import {
  Body,
  CalculatedField,
  ComputedFieldProps,
  Form,
  FormSection,
  Loader,
} from "design-system";
import * as api from "../api";
import { eqExpensesCalculatorData, ExpensesCalculatorData } from "../api";
import { option, taskEither } from "fp-ts";
import {
  LocaleKey,
  useFormatMessage,
  useFormatMoneyAmountValue,
} from "../../../../intl";
import { RegularExpensesForm } from "./RegularExpensesForm";
import { NecessaryExpensesForm } from "./NecessaryExpensesForm";
import { useExpensesCalculatorContext } from "./ExpensesCalculatorProvider";
import { useCommand } from "../../../../useAPI";
import { flow, pipe } from "fp-ts/function";
import {
  MoneyAmount,
  unsafeNonNegativeInteger,
} from "../../../../globalDomain";
import { useTenantCurrency } from "../../../../Common/useTenantCurrency";
import { useState } from "react";
import { useUpdateEffect } from "react-use";

type Props = {
  onCancel: () => unknown;
  onSaved: () => unknown;
  data: ExpensesCalculatorData;
  minMonthlyExpenses: MoneyAmount;
};

export type ComputedFieldPropsExtended<V> = ComputedFieldProps<V> & {
  translationKey: LocaleKey;
};

export function ExpensesCalculator(props: Props) {
  const formatMessage = useFormatMessage();
  const formatMoneyAmountValue = useFormatMoneyAmountValue(
    unsafeNonNegativeInteger(0)
  );
  const tenantCurrency = useTenantCurrency();

  const saveExpenseCalculatorData = useCommand(api.saveExpenseCalculatorData);

  const { necessarySum, calculatorData } = useExpensesCalculatorContext();

  const [isLoading, setIsLoading] = useState(false);

  useUpdateEffect(() => {
    if (isLoading) {
      if (eqExpensesCalculatorData.equals(calculatorData, props.data)) {
        props.onCancel();
      } else {
        pipe(
          saveExpenseCalculatorData({
            expensesCalculator: calculatorData,
          }),
          taskEither.bimap(
            () => setIsLoading(false),
            flow(() => setIsLoading(false), props.onSaved)
          )
        )();
      }
    }
  }, [isLoading]);

  return isLoading ? (
    <Loader />
  ) : (
    <Form
      submitButton={{
        action: taskEither.fromIO(() => {
          let el = document.querySelector(":focus");

          (el as HTMLElement)?.blur();

          setIsLoading(true);
        }),
        label: formatMessage(
          "StandardLoan.ExpensesConfirmation.Calculator.Form.save"
        ),
      }}
      cancelButton={{
        action: props.onCancel,
        label: formatMessage(
          "StandardLoan.ExpensesConfirmation.Calculator.Form.close"
        ),
      }}
    >
      <FormSection>
        <NecessaryExpensesForm
          necessaryExpensesData={props.data.necessaryExpenses}
        />
        <RegularExpensesForm regularExpensesData={props.data.regularExpenses} />
      </FormSection>
      <CalculatedField
        type={"regular"}
        value={formatMoneyAmountValue({
          amount:
            necessarySum > props.minMonthlyExpenses.amount
              ? necessarySum
              : props.minMonthlyExpenses.amount,
          currency: tenantCurrency,
        })}
        label={formatMessage(
          "StandardLoan.ExpensesConfirmation.Calculator.Form.total"
        )}
        error={option.none}
      />
      {necessarySum < props.minMonthlyExpenses.amount && (
        <Body size="x-small" weight="regular">
          {formatMessage(
            "StandardLoan.ExpensesConfirmation.Calculator.Form.footnote"
          )}
        </Body>
      )}
    </Form>
  );
}
