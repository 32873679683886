import { CustomExpensesField, RegularExpenses } from "../api";
import { ComputedFieldPropsExtended } from "./ExpensesCalculator";
import { Option } from "fp-ts/Option";
import { useCallback, useEffect, useMemo, useState } from "react";
import { constant, constVoid, pipe } from "fp-ts/function";
import { array, option, ord, record, taskEither } from "fp-ts";
import {
  RuntimeLocaleKey,
  useFormatMessage,
  useFormatMoneyAmountValue,
} from "../../../../intl";
import {
  CalculatedField,
  FormSection,
  NonNegative,
  unsafeLocalizedString,
  useForm,
} from "design-system";
import { ExpensesSubForm } from "./ExpensesSubForm";
import { useExpensesCalculatorContext } from "./ExpensesCalculatorProvider";
import { unsafeNonNegativeInteger } from "../../../../globalDomain";
import { useTenantCurrency } from "../../../../Common/useTenantCurrency";

type RegularExpensesFormProps = {
  regularExpensesData: RegularExpenses;
};

export function RegularExpensesForm(props: RegularExpensesFormProps) {
  const {
    regularSum,
    setRegularSum,
    setCalculatorData,
  } = useExpensesCalculatorContext();
  const formatMessage = useFormatMessage();
  const formatMoneyAmountValue = useFormatMoneyAmountValue(
    unsafeNonNegativeInteger(0)
  );
  const tenantCurrency = useTenantCurrency();

  const expenses = props.regularExpensesData;

  const [customFieldsUpdate, setCustomFieldUpdate] = useState(1);
  const customFieldsChanged = useCallback(
    () => setCustomFieldUpdate(n => n + 1),
    []
  );
  const getInitialValue = (initialValue: number) =>
    pipe(
      initialValue,
      option.fromPredicate(value => value !== 0)
    );

  const customFieldsValues: Option<number>[] = useMemo(
    () =>
      pipe(
        expenses.customExpensesFields,
        array.sort(
          pipe(
            ord.ordNumber,
            ord.contramap(({ index: rank }: CustomExpensesField) => rank)
          )
        ),
        array.map(item => getInitialValue(item.value))
      ),
    []
  );
  const customFields: ComputedFieldPropsExtended<Option<number>>[] = pipe(
    expenses.customExpensesFields,
    array.sort(
      pipe(
        ord.ordNumber,
        ord.contramap(({ index: rank }: CustomExpensesField) => rank)
      )
    ),
    array.mapWithIndex((index, item) => ({
      value: customFieldsValues[index],
      name: item.key,
      issues: option.none,
      onBlur: constVoid,
      onChange: val => {
        customFieldsValues[index] = val;
        customFieldsChanged();
      },
      disabled: false,
      isTouched: false,
      translationKey: item.key as RuntimeLocaleKey,
    }))
  );

  //TODO should quit using form here
  const { fieldProps, values } = useForm(
    {
      initialValues: {
        regularExpensesfusesAmount: getInitialValue(expenses.fusesAmount),
        regularExpensessavingAmount: getInitialValue(expenses.savingAmount),
        regularExpensespensionAmount: getInitialValue(expenses.pensionAmount),
        regularExpensesotherAmount: getInitialValue(expenses.otherAmount),
      },
      fieldValidators: () => {
        return {};
      },
    },
    { onSubmit: () => taskEither.fromIO(constVoid) }
  );

  const necessaryFP: ComputedFieldPropsExtended<Option<number>>[] = [
    {
      ...fieldProps("regularExpensessavingAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.regular.savingLabel",
    },
    {
      ...fieldProps("regularExpensesfusesAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.regular.fussLabel",
    },
    {
      ...fieldProps("regularExpensespensionAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.regular.pensionLabel",
    },
    {
      ...fieldProps("regularExpensesotherAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.regular.otherLabel",
    },
    ...customFields,
  ];
  const getValue = (fieldvalue: Option<number>) =>
    pipe(fieldvalue, option.getOrElse(constant(0)));

  useEffect(() => {
    const customFieldsSum = pipe(
      customFieldsValues,
      array.reduce(0, (acc, cur) => acc + getValue(cur))
    );
    const fieldsSum = pipe(
      values,
      record.reduce(0, (acc, cur) => acc + getValue(cur))
    );
    setRegularSum(fieldsSum + customFieldsSum);

    setCalculatorData(current => ({
      ...current,
      regularExpenses: {
        customExpensesFields: pipe(
          customFields,
          array.mapWithIndex((index, field) => ({
            index: index as NonNegative,
            value: getValue(field.value) as NonNegative,
            key: field.translationKey,
          }))
        ),
        otherAmount: getValue(values.regularExpensesotherAmount),
        pensionAmount: getValue(values.regularExpensespensionAmount),
        savingAmount: getValue(values.regularExpensessavingAmount),
        fusesAmount: getValue(values.regularExpensesfusesAmount),
      },
    }));
  }, [customFieldsUpdate, values]);

  return (
    <FormSection
      heading={{
        title: formatMessage(
          "StandardLoan.ExpensesConfirmation.Calculator.Form.regular.title"
        ),
        subtitle: unsafeLocalizedString(""),
      }}
    >
      <ExpensesSubForm fields={necessaryFP} />
      <CalculatedField
        type={"regular"}
        value={formatMoneyAmountValue({
          amount: regularSum,
          currency: tenantCurrency,
        })}
        label={formatMessage(
          "StandardLoan.ExpensesConfirmation.Calculator.Form.regular.total"
        )}
        error={option.none}
      />
    </FormSection>
  );
}
