import { constVoid, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../../intl";
import { option, taskEither } from "fp-ts";
import {
  Box,
  Button,
  FormSection,
  LoadingButton,
  Space,
  Stack,
} from "design-system";
import { SelectedCompany } from "../../domain";

import * as api from "../../api";
import { useCommand } from "../../../useAPI";
import {
  IncomeOptionsOutput,
  IncomeOutput,
  IncomeSourceType,
  SpecialIncomeSourceType,
} from "../../IncomeForm/domain";
import { IncomeForm } from "../../IncomeForm/IncomeForm";
import { Option } from "fp-ts/Option";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { useState } from "react";
import { ReworkIncomeOutput } from "../../Rework/api";

type Props = {
  additionalIncomeData: IncomeOutput;
  incomeOptions: IncomeOptionsOutput;
  incomeSources: NonEmptyArray<IncomeSourceType>;
  specialIncomeSources: Option<SpecialIncomeSourceType[]>;
  selectedCompanies: SelectedCompany[];
  isEditing: boolean;
  isReworking: boolean;
  oldValues: Option<ReworkIncomeOutput>;
  onSubmit: () => unknown;
  onCancel: () => unknown;
  onDelete: () => unknown;
  reworkAll: boolean;
  hideCancel?: boolean;
};

export function AdditionalIncomeForm(props: Props) {
  const formatMessage = useFormatMessage();
  const addNewIncome = useCommand(api.additionalIncomeAdd);
  const editIncome = useCommand(api.additionalIncomeEdit);
  const [canSubmit, setCanSubmit] = useState(false);
  const [submitTrigger, setSubmitTrigger] = useState(0);
  const [isCancelling, setIsCancelling] = useState(false);

  const addOrEditIncome = (income: IncomeOutput) =>
    pipe(
      income.uniqueId,
      option.fold(
        () => pipe({ additionalIncome: income }, addNewIncome),
        () => pipe({ additionalIncome: income }, editIncome)
      )
    );

  const onIncomeDataReady = (values: IncomeOutput) =>
    pipe(
      addOrEditIncome(values),
      taskEither.chain(() => taskEither.fromIO(props.onSubmit))
    )();

  const onFailure = () => {
    setCanSubmit(false);
  };

  const onCancel = () => {
    props.onCancel();
    setIsCancelling(true);
  };
  const cancelButton =
    props.hideCancel === true ? (
      <></>
    ) : (
      <Button
        variant="text"
        size="default"
        label={formatMessage("Identification.address.cancel")}
        action={onCancel}
      />
    );

  const submitButton = (
    <Box hAlignContent="right">
      <LoadingButton
        type="submit"
        variant="primary"
        size="default"
        labels={{
          normal: formatMessage("Save"),
          success: formatMessage("Save"),
          loading: formatMessage("Loading"),
          error: formatMessage("Error"),
        }}
        action={taskEither.fromIO(() => {
          if (!canSubmit) {
            setCanSubmit(true);
          } else {
            setSubmitTrigger(val => val + 1);
          }
        })}
      />
    </Box>
  );

  return (
    <FormSection>
      <IncomeForm
        canSubmit={canSubmit}
        submitTrigger={submitTrigger}
        incomeData={{
          ...props.additionalIncomeData,
          incomeSourceList: props.incomeSources,
          specialIncomeSourceList: props.specialIncomeSources,
          incomeOptions: props.incomeOptions,
        }}
        onDataReady={onIncomeDataReady}
        onFailure={onFailure}
        options={{
          isEditing: props.isEditing,
          alreadySelectedCompanies: option.some(props.selectedCompanies),
        }}
        rework={pipe(
          props.oldValues,
          option.map(oldValues => ({
            income: option.some(oldValues),
            personalData: option.none,
          }))
        )}
        isMainIncome={false}
        reworkAll={props.reworkAll}
        onChangeIncomeSourceType={constVoid}
        isCancelling={isCancelling}
        onCancel={setIsCancelling}
        monthlyIncome={option.none}
        salaryCurrency={option.none}
      />
      {props.isEditing && (
        <Stack units={4}>
          {cancelButton}
          <Space fluid />
          {submitButton}
        </Stack>
      )}
    </FormSection>
  );
}
