import { Box, Button, FormSection, FormRow } from "design-system";
import { useFormatMessage } from "../../../intl";
import { ReadOnlyMoneyAmountField } from "../../../Common/ReadOnlyMoneyAmountField/ReadOnlyMoneyAmountField";
import { foldTenant, MoneyAmount } from "../../../globalDomain";
import { Dispatch, SetStateAction } from "react";
import { Option } from "fp-ts/Option";
import { useAppContext } from "../../../useAppContext";
import { pipe } from "fp-ts/function";
import { option } from "fp-ts";

type Props = {
  setReadOnly: Dispatch<SetStateAction<boolean>>;
  minMontlyExpenses: MoneyAmount;
  otherExpenses: Option<number>;
  alimony: Option<number>;
  regularMontlyExpenses: MoneyAmount;
};

export function ReadOnlyExpensesConfirmation(props: Props) {
  const formatMessage = useFormatMessage();

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const optionToZero = (value: Option<number>) =>
    pipe(
      value,
      option.getOrElse(() => 0)
    );

  return (
    <FormSection>
      <FormRow type="full">
        <ReadOnlyMoneyAmountField
          size="x-small"
          value={foldTenant(
            tenant,
            () => ({
              amount:
                props.minMontlyExpenses.amount +
                optionToZero(props.otherExpenses) +
                optionToZero(props.alimony),
              currency: props.minMontlyExpenses.currency,
            }),
            () => ({
              amount:
                props.minMontlyExpenses.amount +
                optionToZero(props.otherExpenses),
              currency: props.minMontlyExpenses.currency,
            })
          )}
          label={formatMessage(
            "StandardLoan.ExpensesConfirmation.Panel.minimumMontlyExpensesLabel"
          )}
          description={formatMessage(
            "StandardLoan.ExpensesConfirmation.Panel.minimumMontlyExpensesDescription"
          )}
        />
      </FormRow>
      <FormRow type="full">
        <ReadOnlyMoneyAmountField
          size="x-small"
          value={props.regularMontlyExpenses}
          label={formatMessage(
            "StandardLoan.ExpensesConfirmation.Panel.regularMontlyExpensesLabel"
          )}
          description={formatMessage(
            "StandardLoan.ExpensesConfirmation.Panel.regularMontlyExpensesDescription"
          )}
        />
      </FormRow>
      <FormRow type="full">
        <Box hAlignContent="left">
          <Button
            label={formatMessage(
              "StandardLoan.ExpensesConfirmation.Panel.editCTA"
            )}
            variant="link"
            action={() => props.setReadOnly(readOnly => !readOnly)}
          />
        </Box>
      </FormRow>
    </FormSection>
  );
}
