import { useState } from "react";
import { Children } from "design-system";
import { FeedbackFormDialog } from "./FeedbackFormDialog";
import { boolean, option, task } from "fp-ts";

import { constNull, pipe } from "fp-ts/function";
import { SaveApplicationDialog } from "./SaveApplicationDialog";
import { PortalStatusAlert } from "../Common/PortalStatusAlert/PortalStatusAlert";
import * as api from "./api";
import { useCommand } from "../useAPI";
import { MainLayout } from "../Common/MainLayout/MainLayout";
import { headerActions } from "../Common/MainContent";

type Props = Omit<
  React.ComponentProps<typeof MainLayout>,
  "onLogoClick" | "iconAction"
> & {
  children: Children;
  isExiting: boolean;
  onExitAction: () => unknown;
  onProcessExit: (isApplicationSaved: boolean) => unknown;
  onDismiss: () => unknown;
};

type ExitProcessStep = "SaveApplication" | "FeedbackForm";

function foldStep<T>(
  whenSaveApplication: () => T,
  whenFeedbackForm: () => T
): (step: ExitProcessStep) => T {
  return step => {
    switch (step) {
      case "SaveApplication":
        return whenSaveApplication();
      case "FeedbackForm":
        return whenFeedbackForm();
    }
  };
}

export default function ExitProcessDialogWrapper(props: Props) {
  const [step, setStep] = useState<ExitProcessStep>("SaveApplication");
  const saveApplication = useCommand(api.saveApplication);

  const onDismiss = () => {
    window.onunload = null;
    setStep("SaveApplication");
    props.onDismiss();
  };

  const onSaveAndExit = () => {
    window.onunload = null;
    return pipe(
      saveApplication(),
      task.chain(() => task.fromIO(() => props.onProcessExit(true)))
    )();
  };

  return (
    <>
      <MainLayout
        {...props}
        onLogoClick={option.some(props.onExitAction)}
        iconAction={option.some(headerActions.exit(props.onExitAction))}
      >
        <PortalStatusAlert inAuthenticatedPage />
        {props.children}
      </MainLayout>
      {pipe(
        props.isExiting,
        boolean.fold(constNull, () =>
          pipe(
            step,
            foldStep(
              () => (
                <SaveApplicationDialog
                  onDismiss={onDismiss}
                  onSave={onSaveAndExit}
                  onCancel={() => setStep("FeedbackForm")}
                />
              ),
              () => (
                <FeedbackFormDialog
                  onDismiss={onDismiss}
                  onSubmit={() => props.onProcessExit(false)}
                />
              )
            )
          )
        )
      )}
    </>
  );
}
