import { InlineMessage, Banner, Space } from "design-system";
import { boolean, option } from "fp-ts";
import {
  constant,
  constFalse,
  constNull,
  constTrue,
  identity,
  pipe,
} from "fp-ts/function";
import { useFormatMessage, useFormatMoneyAmount } from "../../intl";
import { Currency, foldTenant, MoneyAmount } from "../../globalDomain";
import * as api from "../api";
import {
  foldPreapprovedLimits,
  foldCLPreapprovedLimits,
  isTL,
} from "../../ClientProfile/domain";
import { useAppContext } from "../../useAppContext";
import { Option } from "fp-ts/Option";
import { foldFlowType, StandardLoanFlowType } from "../domain";
import { useState } from "react";
import {
  foldAPIStatus,
  PreapprovedLimitState,
} from "./UpdateCustomerOfferContext";
import { SelectedLoansInformation } from "../Refinancing/RefinancingAccountSelection";

const remoteLimitAmount = 10000;

interface OfferLimitsInformation {
  isAmountOverLimit: Option<boolean>;
  preapprovedLimit: Option<MoneyAmount>;
  topUpLimit: Option<MoneyAmount>;
  moneyAmountLimit: Option<MoneyAmount>;
}

export const useOfferLimitsInformation = (
  flowType: StandardLoanFlowType,
  loanOffer: api.GenericLoanResponseOutput,
  wayOfIdentification: api.WayOfIdentification,
  preapprovedLimitState: PreapprovedLimitState,
  isTopUpActive: boolean,
  allInternalLoanSelected: boolean,
  selectedRefinancingCredits: SelectedLoansInformation
): OfferLimitsInformation => {
  const {
    apiParameters: { tenant },
  } = useAppContext();

  const topUpLimit: Option<MoneyAmount> = pipe(
    preapprovedLimitState,
    foldAPIStatus({
      whenSuccess: foldPreapprovedLimits({
        whenTl: ({ preapprovedLimits }) =>
          pipe(
            preapprovedLimits.TL,
            option.map(TL => ({
              amount: TL.maximumpreapprovedlimit,
              currency: TL.currency,
            }))
          ),
        whenCl: ({ preapprovedLimits }) =>
          pipe(
            preapprovedLimits.CL,
            option.map(CL => ({
              amount: CL.maximumpreapprovedlimit,
              currency: CL.currency,
            }))
          ),
        whenEmpty: () => option.none,
      }),
      whenLoading: () => option.none,
      whenError: () => option.none,
    })
  );

  const preapprovedLimit: Option<MoneyAmount> = pipe(
    preapprovedLimitState,
    foldAPIStatus({
      whenSuccess: foldCLPreapprovedLimits({
        whenCl: ({ preapprovedLimits }) =>
          pipe(
            preapprovedLimits.CL,
            option.map(CL => ({
              amount: CL.maximumpreapprovedlimit,
              currency: CL.currency,
            }))
          ),
        whenNotCL: () => option.none,
      }),
      whenLoading: () => option.none,
      whenError: () => option.none,
    })
  );

  const countPreapprovedLimit = () =>
    pipe(
      preapprovedLimit,
      option.chain(limit =>
        pipe(
          loanOffer.refinancedAmountExternal,
          option.fold(
            () => option.some(limit),
            refinancedAmountExternal =>
              option.some({
                amount:
                  limit.amount -
                  refinancedAmountExternal -
                  selectedRefinancingCredits.internal.amount,
                currency: limit.currency,
              })
          )
        )
      )
    );

  const getTopUpInstallment = () =>
    pipe(
      preapprovedLimitState,
      foldAPIStatus({
        whenSuccess: foldPreapprovedLimits({
          whenTl: ({ preapprovedLimits }) =>
            pipe(
              preapprovedLimits.TL,
              option.map(TL => TL.maximuminstallment)
            ),
          whenCl: ({ preapprovedLimits }) =>
            pipe(
              preapprovedLimits.CL,
              option.map(CL => CL.maximuminstallment)
            ),
          whenEmpty: () => option.none,
        }),
        whenLoading: () => option.none,
        whenError: () => option.none,
      })
    );

  const getPreapprovedInstallment = () =>
    pipe(
      preapprovedLimitState,
      foldAPIStatus({
        whenSuccess: foldCLPreapprovedLimits({
          whenCl: ({ preapprovedLimits }) =>
            pipe(
              preapprovedLimits.CL,
              option.map(CL => CL.maximuminstallment)
            ),
          whenNotCL: () => option.none,
        }),
        whenLoading: () => option.none,
        whenError: () => option.none,
      })
    );

  const refinancedAmountExternal = pipe(
    loanOffer.refinancedAmountExternal,
    option.fold(
      () => 0,
      refinancedAmountExternal => refinancedAmountExternal
    )
  );
  const refinancedInternalCCOVD = pipe(
    loanOffer.refinancedInternalCCOVD,
    option.fold(
      () => 0,
      refinancedInternalCCOVD => refinancedInternalCCOVD
    )
  );

  const countTopUpLimit = () =>
    pipe(
      topUpLimit,
      option.chain(limit =>
        option.some({
          amount:
            limit.amount - refinancedAmountExternal - refinancedInternalCCOVD,
          currency: limit.currency,
        })
      )
    );

  const moneyAmountLimit = pipe(
    preapprovedLimitState,
    foldAPIStatus({
      whenSuccess: limitData =>
        pipe(
          !isTL(limitData) && allInternalLoanSelected,
          boolean.fold(
            () =>
              pipe(
                isTopUpActive,
                boolean.fold(countPreapprovedLimit, countTopUpLimit)
              ),
            countPreapprovedLimit
          )
        ),
      whenLoading: () => option.none,
      whenError: () => option.none,
    })
  );

  const preApprovedInstallmentLimit = pipe(
    isTopUpActive,
    boolean.fold(getTopUpInstallment, getPreapprovedInstallment)
  );

  const isTenantSK = foldTenant(tenant, constTrue, constFalse);
  const hasAmountLimit = pipe(
    moneyAmountLimit,
    option.fold(() => {
      const isFlowRemote =
        pipe(
          flowType,
          foldFlowType({
            when3P: constFalse,
            whenInPerson: constFalse,
            whenTLSAgent: constTrue,
            whenHomeBanking: constTrue,
            whenSmartBanking: constTrue,
            whenPWSRemote: constTrue,
          })
        ) && wayOfIdentification === "REMOTE";

      const hasLimits = pipe(
        preapprovedLimitState,
        foldAPIStatus({
          whenSuccess: foldPreapprovedLimits({
            whenEmpty: constFalse,
            whenCl: constTrue,
            whenTl: constTrue,
          }),
          whenLoading: constFalse,
          whenError: constFalse,
        })
      );

      return isTenantSK && isFlowRemote && !hasLimits;
    }, constTrue)
  );

  const bankingFee = pipe(loanOffer.bankingFee, option.getOrElse(constant(0)));
  const feeToBeSubtracted = pipe(
    loanOffer.bankingFeeIncluded,
    option.getOrElse(constTrue),
    boolean.fold(constant(0), constant(bankingFee))
  );

  const limitAmount = pipe(
    moneyAmountLimit,
    option.fold(
      () => remoteLimitAmount - feeToBeSubtracted,
      limit => limit.amount
    )
  );

  const preApprovedInstallment = pipe(
    preApprovedInstallmentLimit,
    option.fold(
      () => 0,
      installment => installment
    )
  );

  const consideredAmount = pipe(
    moneyAmountLimit,
    option.fold(
      () => loanOffer.totalAmount,
      () => loanOffer.amount
    )
  );

  const isAmountOverLimit = hasAmountLimit
    ? option.some(
        consideredAmount > limitAmount ||
          (loanOffer.installment > preApprovedInstallment &&
            preApprovedInstallment > 0)
      )
    : option.none;

  return {
    isAmountOverLimit,
    preapprovedLimit,
    topUpLimit,
    moneyAmountLimit,
  };
};

type Props = {
  isAmountOverLimit: Option<boolean>;
  moneyAmountLimit: Option<MoneyAmount>;
  currency: Currency;
  onExit: (isApplicationSaved: boolean) => unknown;
  loanOffer: api.GenericLoanResponseOutput;
};

export function LimitWarning(props: Props) {
  const formatMessage = useFormatMessage();
  const formatMoneyAmount = useFormatMoneyAmount();
  const [isWarningVisible, setIsWarningVisible] = useState(true);

  const {
    apiParameters: { channel },
  } = useAppContext();

  const isTls = channel === "TLS_Remote";

  const warningForAdditionalIncome = (limitExceeded: boolean) =>
    pipe(
      limitExceeded && isWarningVisible,
      boolean.fold(constNull, () => (
        <>
          <Space units={4} />
          <Banner
            type="warning"
            content={formatMessage(
              "StandardLoan.CustomerOffer.detailsModal.sk.preapprovedLimit.exceeded"
            )}
            actions={option.none}
            onDismiss={option.none}
            title={option.none}
          />
        </>
      ))
    );

  const preApprovedLimitMessage = (
    moneyAmountLimit: MoneyAmount,
    limitExceeded: boolean
  ) =>
    pipe(
      moneyAmountLimit.amount < 0,
      boolean.fold(
        () => (
          <>
            <InlineMessage
              type={limitExceeded ? "warning" : "informative"}
              message={formatMessage(
                "StandardLoan.CustomerOffer.detailsModal.sk.preapprovedLimit.amount",
                formatMoneyAmount(moneyAmountLimit, identity)
              )}
              size="medium"
            />
            {warningForAdditionalIncome(limitExceeded)}
          </>
        ),
        () => <>{warningForAdditionalIncome(limitExceeded)}</>
      )
    );

  const bankingFee = pipe(
    props.loanOffer.bankingFee,
    option.getOrElse(constant(0))
  );
  const feeToBeSubtracted = pipe(
    props.loanOffer.bankingFeeIncluded,
    option.getOrElse(constTrue),
    boolean.fold(constant(0), constant(bankingFee))
  );

  return pipe(
    props.isAmountOverLimit,
    option.fold(constNull, limitExceeded =>
      pipe(
        props.moneyAmountLimit,
        option.fold(
          () => (
            <>
              <InlineMessage
                type={limitExceeded ? "warning" : "informative"}
                message={formatMessage(
                  "StandardLoan.CustomerOffer.detailsModal.sk.remoteLimit.amount",
                  formatMoneyAmount(
                    {
                      amount: remoteLimitAmount - feeToBeSubtracted,
                      currency: props.currency,
                    },
                    identity
                  )
                )}
                size="medium"
              />
              {pipe(
                limitExceeded && isWarningVisible,
                boolean.fold(constNull, () => (
                  <>
                    <Space units={4} />
                    <Banner
                      type="warning"
                      content={formatMessage(
                        isTls
                          ? "StandardLoan.CustomerOffer.detailsModal.sk.remoteLimit.instructionsForTLSOperator"
                          : "StandardLoan.CustomerOffer.detailsModal.sk.remoteLimit.exceeded"
                      )}
                      actions={option.some([
                        {
                          label: formatMessage(
                            "StandardLoan.CustomerOffer.detailsModal.sk.remoteLimit.saveAndClose"
                          ),
                          variant: "secondary",
                          action: () => props.onExit(true),
                        },
                      ])}
                      onDismiss={option.some(() => setIsWarningVisible(false))}
                      title={option.none}
                    />
                  </>
                ))
              )}
            </>
          ),
          moneyAmountLimit =>
            pipe(
              props.loanOffer.eligibleForPreapproved,
              option.fold(
                () => preApprovedLimitMessage(moneyAmountLimit, limitExceeded),
                eligibleForPreapproved =>
                  eligibleForPreapproved === 1 ? (
                    preApprovedLimitMessage(moneyAmountLimit, limitExceeded)
                  ) : (
                    <></>
                  )
              )
            )
        )
      )
    )
  );
}
