import { Option } from "fp-ts/Option";
import { useCallback, useEffect, useMemo, useState } from "react";
import { constant, constVoid, pipe } from "fp-ts/function";
import { array, option, ord, record, taskEither } from "fp-ts";
import { CustomExpensesField, NecessaryExpenses } from "../api";
import {
  RuntimeLocaleKey,
  useFormatMessage,
  useFormatMoneyAmountValue,
} from "../../../../intl";
import {
  CalculatedField,
  FormSection,
  InlineMessage,
  NonNegative,
  useForm,
} from "design-system";
import { ExpensesSubForm } from "./ExpensesSubForm";
import { ComputedFieldPropsExtended } from "./ExpensesCalculator";
import { useExpensesCalculatorContext } from "./ExpensesCalculatorProvider";
import { unsafeNonNegativeInteger } from "../../../../globalDomain";
import { useTenantCurrency } from "../../../../Common/useTenantCurrency";

type NecessaryExpensesProps = {
  necessaryExpensesData: NecessaryExpenses;
};

export function NecessaryExpensesForm(props: NecessaryExpensesProps) {
  const {
    necessarySum,
    setNecessarySum,
    setCalculatorData,
  } = useExpensesCalculatorContext();

  const formatMessage = useFormatMessage();
  const formatMoneyAmountValue = useFormatMoneyAmountValue(
    unsafeNonNegativeInteger(0)
  );
  const tenantCurrency = useTenantCurrency();

  const [expenses] = useState(props.necessaryExpensesData);

  const [customFieldsUpdate, setCustomFieldUpdate] = useState(1);
  const customFieldsChanged = useCallback(
    () => setCustomFieldUpdate(n => n + 1),
    []
  );
  const getInitialValue = (initialValue: number) =>
    pipe(
      initialValue,
      option.fromPredicate(value => value !== 0)
    );

  const customFieldsValues: Option<number>[] = useMemo(
    () =>
      pipe(
        expenses.customExpensesFields,
        array.sort(
          pipe(
            ord.ordNumber,
            ord.contramap(({ index: rank }: CustomExpensesField) => rank)
          )
        ),
        array.map(item => getInitialValue(item.value))
      ),
    []
  );
  const customFields: ComputedFieldPropsExtended<Option<number>>[] = pipe(
    expenses.customExpensesFields,
    array.sort(
      pipe(
        ord.ordNumber,
        ord.contramap(({ index }: CustomExpensesField) => index)
      )
    ),
    array.mapWithIndex((index, item) => ({
      value: customFieldsValues[index],
      name: item.key,
      issues: option.none,
      onBlur: constVoid,
      onChange: val => {
        customFieldsValues[index] = val;
        customFieldsChanged();
      },
      disabled: false,
      isTouched: false,
      translationKey: item.key as RuntimeLocaleKey,
    }))
  );

  //TODO should quit using form here
  const { fieldProps, values } = useForm(
    {
      initialValues: {
        necessaryExpensescommonPurchasesAmount: getInitialValue(
          expenses.commonPurchasesAmount
        ),
        necessaryExpensesalimonyAmount: getInitialValue(expenses.alimonyAmount),
        necessaryExpensescateringAmount: getInitialValue(
          expenses.cateringAmount
        ),
        necessaryExpensesclothesAmount: getInitialValue(expenses.clothesAmount),
        necessaryExpenseseducationAmount: getInitialValue(
          expenses.educationAmount
        ),
        necessaryExpensesentertainmentAmount: getInitialValue(
          expenses.entertainmentAmount
        ),
        necessaryExpenseshealthAmount: getInitialValue(expenses.healthAmount),
        necessaryExpenseshousingAmount: getInitialValue(expenses.housingAmount),
        necessaryExpensesrepairsAmount: getInitialValue(expenses.repairsAmount),
        necessaryExpensestransportAmount: getInitialValue(
          expenses.transportAmount
        ),
        necessaryExpensesotherAmount: getInitialValue(expenses.otherAmount),
      },
      fieldValidators: () => {
        return {};
      },
    },
    { onSubmit: () => taskEither.fromIO(constVoid) }
  );

  const necessaryFP: ComputedFieldPropsExtended<Option<number>>[] = [
    {
      ...fieldProps("necessaryExpensescommonPurchasesAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.commonPurchasesLabel",
    },
    {
      ...fieldProps("necessaryExpensesentertainmentAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.entertainmentLabel",
    },
    {
      ...fieldProps("necessaryExpenseshousingAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.housingLabel",
    },
    {
      ...fieldProps("necessaryExpenseseducationAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.EducationLabel",
    },
    {
      ...fieldProps("necessaryExpensestransportAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.transportLabel",
    },
    {
      ...fieldProps("necessaryExpensesalimonyAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.alimonyLabel",
    },
    {
      ...fieldProps("necessaryExpensesclothesAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.clothesLabel",
    },
    {
      ...fieldProps("necessaryExpensescateringAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.cateringLabel",
    },
    {
      ...fieldProps("necessaryExpensesrepairsAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.apartmentRepairLabel",
    },
    {
      ...fieldProps("necessaryExpensesotherAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.otherLabel",
    },
    {
      ...fieldProps("necessaryExpenseshealthAmount"),
      translationKey:
        "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.healthLabel",
    },
    ...customFields,
  ];
  const getValue = (fieldvalue: Option<number>) =>
    pipe(fieldvalue, option.getOrElse(constant(0)));

  useEffect(() => {
    const customFieldsSum = pipe(
      customFieldsValues,
      array.reduce(0, (acc, cur) => acc + getValue(cur))
    );
    const fieldsSum = pipe(
      values,
      record.reduce(0, (acc, cur) => acc + getValue(cur))
    );

    setNecessarySum(fieldsSum + customFieldsSum);

    setCalculatorData(current => ({
      ...current,
      necessaryExpenses: {
        customExpensesFields: pipe(
          customFields,
          array.mapWithIndex((index, field) => ({
            index: index as NonNegative,
            value: getValue(field.value) as NonNegative,
            key: field.translationKey,
          }))
        ),
        otherAmount: getValue(values.necessaryExpensesotherAmount),
        transportAmount: getValue(values.necessaryExpensestransportAmount),
        repairsAmount: getValue(values.necessaryExpensesrepairsAmount),
        housingAmount: getValue(values.necessaryExpenseshousingAmount),
        healthAmount: getValue(values.necessaryExpenseshealthAmount),
        entertainmentAmount: getValue(
          values.necessaryExpensesentertainmentAmount
        ),
        educationAmount: getValue(values.necessaryExpenseseducationAmount),
        clothesAmount: getValue(values.necessaryExpensesclothesAmount),
        cateringAmount: getValue(values.necessaryExpensescateringAmount),
        alimonyAmount: getValue(values.necessaryExpensesalimonyAmount),
        commonPurchasesAmount: getValue(
          values.necessaryExpensescommonPurchasesAmount
        ),
      },
    }));
  }, [customFieldsUpdate, values]);

  return (
    <FormSection
      heading={{
        title: formatMessage(
          "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.title"
        ),
      }}
    >
      <InlineMessage
        type="informative"
        message={formatMessage(
          "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.description"
        )}
        size="medium"
      />

      <ExpensesSubForm fields={necessaryFP} />

      <CalculatedField
        type={"regular"}
        value={formatMoneyAmountValue({
          amount: necessarySum,
          currency: tenantCurrency,
        })}
        label={formatMessage(
          "StandardLoan.ExpensesConfirmation.Calculator.Form.necessary.total"
        )}
        error={option.none}
      />
    </FormSection>
  );
}
