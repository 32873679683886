import { EntryFee } from "../api";
import { boolean, option } from "fp-ts";
import {
  Body,
  Box,
  FieldProps,
  QuestionIcon,
  RadioGroupField,
  Tooltip,
  unsafeLocalizedString,
} from "design-system";
import { constFalse, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useFormatMessage, useFormatMoneyAmountValue } from "../../intl";
import { useTenantCurrency } from "../../Common/useTenantCurrency";
import { forwardRef } from "react";
import { palette, spaceUnit } from "design-system/lib/styleConstants";

type Props = {
  entryFee: EntryFee;
} & FieldProps<Option<boolean>>;

export const EntryFeeRadioGroup = forwardRef<HTMLElement, Props>(
  (props, forwardedRef) => {
    const formatMessage = useFormatMessage();
    const formatMoneyAmount = useFormatMoneyAmountValue();
    const tenantCurrency = useTenantCurrency();

    const renderOption = (feeIncluded: boolean) =>
      pipe(
        feeIncluded,
        boolean.fold(
          () =>
            formatMessage(
              "StandardLoan.CustomerOffer.entryFee.notIncluded.text",
              {
                entryFee: formatMoneyAmount({
                  amount: props.entryFee.amount,
                  currency: tenantCurrency,
                }),
              }
            ),
          () =>
            formatMessage("StandardLoan.CustomerOffer.entryFee.included.text", {
              entryFee: formatMoneyAmount({
                amount: props.entryFee.amount,
                currency: tenantCurrency,
              }),
            })
        )
      );

    return (
      <Box column ref={forwardedRef}>
        <Body size={"medium"} weight={"bold"}>
          {formatMessage("StandardLoan.CustomerOffer.entryFee.title")}
        </Body>
        <RadioGroupField
          {...props}
          label={unsafeLocalizedString("")}
          name={unsafeLocalizedString("")}
          variant="vertical"
          options={[true, false]}
          optionKey={value => value.toString()}
          renderOption={() => unsafeLocalizedString("")}
          issues={props.issues}
          renderOptionChildren={included =>
            option.some(
              <Box grow style={{ marginTop: -2 * spaceUnit }}>
                <Body size={"medium"} weight={"regular"}>
                  {renderOption(included)}
                </Body>
                <span
                  style={{ position: "relative", marginTop: -1 * spaceUnit }}
                >
                  <Tooltip
                    inline
                    content={formatMessage(
                      included
                        ? "StandardLoan.CustomerOffer.entryFee.included.info"
                        : "StandardLoan.CustomerOffer.entryFee.notIncluded.info"
                    )}
                    children={
                      <QuestionIcon color={palette.blue600} size="default" />
                    }
                  />
                </span>
              </Box>
            )
          }
          isOptionDisabled={constFalse}
        />
      </Box>
    );
  }
);
