import { Tooltip } from "design-system";
import { option } from "fp-ts";
import { constant, pipe } from "fp-ts/function";
import { useFormatMessage } from "../../intl";
import { Option } from "fp-ts/Option";
import { useAppContext } from "../../useAppContext";

type Props = {
  isAmountOverLimit: Option<boolean>;
  renderChildren: (disabled: boolean) => JSX.Element;
};

export function RemoteLimitTooltip({
  isAmountOverLimit,
  renderChildren,
}: Props) {
  const formatMessage = useFormatMessage();

  const {
    apiParameters: { channel },
  } = useAppContext();

  const isTls = channel === "TLS_Remote";

  return pipe(
    isAmountOverLimit,
    option.fold(constant(renderChildren(false)), limitExceeded => {
      if (limitExceeded) {
        return (
          <Tooltip
            content={formatMessage(
              isTls
                ? "StandardLoan.CustomerOffer.detailsModal.sk.remoteLimit.instructionsForTLSOperator"
                : "StandardLoan.CustomerOffer.detailsModal.sk.remoteLimit.exceeded"
            )}
          >
            {renderChildren(true)}
          </Tooltip>
        );
      } else {
        return renderChildren(false);
      }
    })
  );
}
