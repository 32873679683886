import { Box, FeedbackBlock } from "design-system";
import { useEffect } from "react";
import { pipe } from "fp-ts/function";
import { boolean, option, taskEither } from "fp-ts";
import { CFExistingErrorType } from "../../ClientProfile/domain";
import { useCommand } from "../../useAPI";
import * as slApi from "../api";
import * as clientApi from "../../ClientProfile/api";
import {
  CheckClientAge,
  CheckPrimaryIdEnumsCF,
  ExistingClientCheckKycCF,
  ExistingClientCheckNotesCF,
} from "../api";
import { RejectionReason } from "../StandardLoanState";
import { useFormatMessage } from "../../intl";
import { StandardLoanFlowType } from "../domain";

interface Props {
  onSuccess: () => unknown;
  onRequestFailure: (reason: RejectionReason) => unknown;
  hasPasswordForCommunication: boolean;
  isPhoneNumberVerified: boolean;
  flowType: StandardLoanFlowType;
  isApplicationExisting: boolean;
  onExit: () => unknown;
}

export const ExistingClientSuspense = (props: Props) => {
  const formatMessage = useFormatMessage();
  const checkSLMinimumAge = useCommand(slApi.checkMinimumAge);
  const checkPrivateClient = useCommand(clientApi.checkPrivateClient);
  const checkForeign = useCommand(slApi.checkForeign);
  const standardLoanCheckNotes = useCommand(slApi.checkNotes);
  const standardLoanCheckPrimaryIdInPerson = useCommand(
    slApi.existingClientPrimaryIdCheck
  );
  const standardLoanCheckKyc = useCommand(slApi.existingClientKYCCheck);
  const standardLoanCheckPhoneNumberAndEmail = useCommand(
    slApi.existingClientPhoneAndEmailCheck
  );

  const onFailure = (reason: CFExistingErrorType) => {
    if (reason.id !== "SUCCESS") {
      switch (reason.id) {
        case "GenericError":
          return props.onRequestFailure("CBRejected");
        case "hasBlockingNotesCF":
          return props.onRequestFailure("hasBlockingNotesCF");
        case "invalidAgeCF":
          return props.onRequestFailure("invalidAgeCF");
        case "privateClient":
          return props.onRequestFailure("privateClient");
        case "phoneNumberNotVerifiedCF":
          return props.onRequestFailure("phoneNumberNotVerifiedCF");
        case "phoneNumberMissingCF":
          return props.onRequestFailure("phoneNumberMissingCF");
        case "highRiskClientCF":
          return props.onRequestFailure("highRiskClientCF");
        case "hasNotPasswordForCommunicationCF":
          return props.onRequestFailure("hasNotPasswordForCommunicationCF");
        case "foreignClientCF":
          return props.onRequestFailure("foreignClientCF");
        case "KYC_ID_FIXED_EZY":
          return props.onRequestFailure("KYC_ID_FIXED_EZY");
        case "KYC_FIXED_EZY":
          return props.onRequestFailure("KYC_FIXED_EZY");
        case "KYC_FIXED_EZY_UPDATE_CONTACTS":
          return props.onRequestFailure("KYC_FIXED_EZY_UPDATE_CONTACTS");
        case "UPDATE_CONTACTS":
          return props.onRequestFailure("UPDATE_CONTACTS");
        case "KYC_ID_FIXED_EZY_UPDATE_CONTACTS":
          return props.onRequestFailure("KYC_ID_FIXED_EZY_UPDATE_CONTACTS");
        case "ID_FIXED_EZY":
          return props.onRequestFailure("ID_FIXED_EZY");
        case "ID_FIXED_EZY_UPDATE_CONTACTS":
          return props.onRequestFailure("ID_FIXED_EZY_UPDATE_CONTACTS");
        case "UPDATE_ID":
          return props.onRequestFailure("UPDATE_ID");
        case "UPDATE_ID_CONTACTS":
          return props.onRequestFailure("UPDATE_ID_CONTACTS");
        case "UPDATE_PASSWORD":
          return props.onRequestFailure("UPDATE_PASSWORD");
        case "UPDATE_ID_CONTACTS_PASSWORD":
          return props.onRequestFailure("UPDATE_ID_CONTACTS_PASSWORD");
        case "UPDATE_ID_PASSWORD":
          return props.onRequestFailure("UPDATE_ID_PASSWORD");
        case "UPDATE_ID_OTHER_THAN_AUTH_ID":
          return props.onRequestFailure("UPDATE_ID_OTHER_THAN_AUTH_ID");
        case "UPDATE_ID_MISSING_INFORMATION":
          return props.onRequestFailure("UPDATE_ID_MISSING_INFORMATION");
      }
    }
  };

  const existingClientChecks = (
    hasPasswordForCommunication: boolean,
    phoneNumberVerified: boolean
  ) =>
    pipe(
      phoneNumberVerified,
      boolean.fold(
        () =>
          taskEither.left<CFExistingErrorType>({
            id: "phoneNumberNotVerifiedCF",
          }),
        () => taskEither.right(null)
      ),
      taskEither.chain(() =>
        pipe(
          checkPrivateClient(),
          taskEither.filterOrElse<
            CFExistingErrorType,
            clientApi.ExistingClientPrivateCheck
          >(
            response => !response.privateClient,
            () => ({ id: "privateClient" })
          )
        )
      ),
      taskEither.chain(() =>
        pipe(
          checkSLMinimumAge(),
          taskEither.filterOrElse<CFExistingErrorType, CheckClientAge>(
            response => !response.underAge,
            () => ({ id: "invalidAgeCF" })
          )
        )
      ),
      taskEither.chain(() =>
        pipe(
          checkForeign(),
          taskEither.filterOrElse<CFExistingErrorType, boolean>(
            response => !response,
            () => ({ id: "foreignClientCF" })
          )
        )
      ),
      taskEither.chain(() =>
        pipe(
          standardLoanCheckNotes(),
          taskEither.filterOrElse<
            CFExistingErrorType,
            ExistingClientCheckNotesCF
          >(
            response => !response.hasBlockingNotes,
            () => ({ id: "hasBlockingNotesCF" })
          )
        )
      ),
      taskEither.chain(() =>
        pipe(
          standardLoanCheckKyc(),
          taskEither.filterOrElse<
            CFExistingErrorType,
            ExistingClientCheckKycCF
          >(
            response => !response.highRisk,
            () => ({ id: "highRiskClientCF" })
          )
        )
      ),
      taskEither.chain(() =>
        pipe(
          standardLoanCheckPhoneNumberAndEmail(),
          taskEither.filterOrElse<
            CFExistingErrorType,
            slApi.ExistingClientCheckPhoneAndEmail
          >(
            response => !response.phoneNumberNeeded,
            () => ({ id: "phoneNumberMissingCF" })
          )
        )
      ),
      taskEither.chain(() =>
        pipe(
          standardLoanCheckPrimaryIdInPerson(),
          taskEither.filterOrElse<CFExistingErrorType, CheckPrimaryIdEnumsCF>(
            response => response === "SUCCESS",
            response => ({ id: response })
          )
        )
      ),
      taskEither.chain(() => {
        if (!hasPasswordForCommunication) {
          return taskEither.left<CFExistingErrorType>({
            id: "hasNotPasswordForCommunicationCF",
          });
        } else {
          return taskEither.right(null);
        }
      }),
      taskEither.bimap(onFailure, props.onSuccess)
    );

  useEffect(() => {
    existingClientChecks(
      props.hasPasswordForCommunication,
      props.isPhoneNumberVerified
    )();
  }, []);

  const content = ((): JSX.Element => {
    return (
      <Box height="100vh" hAlignContent="center" vAlignContent="center">
        <FeedbackBlock
          size="large"
          subheading={option.none}
          type="loading"
          heading={formatMessage("UserValidation.loading.title")}
        />
      </Box>
    );
  })();

  return <>{content}</>;
};
