import { Liabilities } from "../../../../MortgageDashboard/Registers/CBResults/Liabilities";
import { option, array } from "fp-ts";
import { useQuery } from "../../../../useAPI";
import {
  Box,
  Loader,
  ErrorBanner,
  unsafeNonNegative,
  unsafeLocalizedString,
} from "design-system";
import { pipe, constNull, constVoid } from "fp-ts/function";
import * as remoteData from "../../../../RemoteData";
import { useFormatMessage } from "../../../../intl";
import { getCurrentApplicant } from "../../../../MortgageDashboard/Applicants/applicantsUtils";
import { SelectedApplicant } from "../../../../MortgageDashboard/mortgageDashboardUtils";
import * as api from "./api";
import { sequenceS } from "fp-ts/Apply";
import * as standartLoanApi from "../../../api";

type Props = {
  onClose: () => unknown;
};

export function CurrentLiabilitiesDialogContent(props: Props) {
  const formatMessage = useFormatMessage();
  const [start] = useQuery(api.start);
  const [allLiabilities] = useQuery(api.getAllLiabilities);

  const defaultApplicant: SelectedApplicant = {
    type: "MainApplicant",
    index: unsafeNonNegative(0),
  };

  const [loanProviders] = useQuery(standartLoanApi.loanProviders);

  return (
    <Box grow shrink column>
      {pipe(
        { loanProviders, allLiabilities, start },
        sequenceS(remoteData.remoteData),
        remoteData.fold(
          () => (
            <Box hAlignContent="center">
              <Loader />
            </Box>
          ),
          () => <ErrorBanner children={formatMessage("GenericError")} />,
          ({ loanProviders, allLiabilities }) => {
            const results = getCurrentApplicant(defaultApplicant, [
              {
                applicantIndex: unsafeNonNegative(0),
                data: allLiabilities,
              },
            ]);

            const providersList = pipe(
              loanProviders.loanProviderList,
              array.map(provider => ({
                label: unsafeLocalizedString(provider.name),
                value: provider.code,
              }))
            );

            return pipe(
              results,
              option.fold(
                constNull,
                ({
                  overdraftsList,
                  creditCardsList,
                  consumerLoansOrMortgagesList,
                  mtgAndClMonthlyInstallmentLimit,
                  mtgAndClCurrentBalanceLimit,
                  mtgAndClApprovedLoanAmountLimit,
                  ccAndOvdCurrentBalanceLimit,
                  ccAndOvdLimit,
                }) => (
                  <Liabilities
                    isViewMode={false}
                    isACPhase={false}
                    loanList={{
                      overdraftsList,
                      creditCardsList,
                      consumerLoansOrMortgagesList,
                    }}
                    onCancel={props.onClose}
                    onSave={props.onClose}
                    selectedApplicant={defaultApplicant}
                    isBonitaEvaluated={false}
                    selectedOfferLoanAmount={option.none}
                    othersLoanList={[]}
                    amountLimits={{
                      monthlyInstallmentAmount: mtgAndClMonthlyInstallmentLimit,
                      mtgCurrentBalance: mtgAndClCurrentBalanceLimit,
                      mtgOriginalLoanAmount: mtgAndClApprovedLoanAmountLimit,
                      ccAndOvdCurrentBalance: ccAndOvdCurrentBalanceLimit,
                      ccAndOvdLimit: ccAndOvdLimit,
                    }}
                    providersList={providersList}
                    onEditStart={constVoid}
                    onEditEnd={constVoid}
                    variant="CF"
                    reworkCBResults={option.none}
                    checks={option.none}
                    updateChecks={constVoid}
                  />
                )
              )
            );
          }
        )
      )}
    </Box>
  );
}
