import {
  Banner,
  CheckboxField,
  Dialog,
  Form,
  FormRow,
  FormSection,
  Space,
  TextareaField,
  useForm,
} from "design-system";
import { option, taskEither, these } from "fp-ts";
import { Option } from "fp-ts/Option";
import { useFormatMessage } from "../intl";

import { constVoid, identity, pipe } from "fp-ts/function";
import { useCommand } from "../useAPI";
import { deleteApplication, exitProcess, ExitProcessInput } from "./api";
import { useState } from "react";

interface Props {
  onDismiss: () => unknown;
  onSubmit: () => unknown;
}

export function FeedbackFormDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const deleteCurrentApplication = useCommand(deleteApplication);
  const sendFeedback = useCommand(exitProcess);
  const [showErrorBanner, setShowErrorBanner] = useState(false);

  function submitFeedback(input: ExitProcessInput) {
    return pipe(
      deleteCurrentApplication(),
      taskEither.chain(() =>
        taskEither.fromIO(() => {
          window.onunload = null;
        })
      ),
      taskEither.chain(() => sendFeedback(input)),
      taskEither.fold(
        () => taskEither.fromIO(() => setShowErrorBanner(true)),
        () => taskEither.fromIO(props.onSubmit)
      )
    );
  }

  const { fieldProps, values, setValues, handleSubmit } = useForm(
    {
      initialValues: {
        needsHigherAmount: false,
        needsShorterTenor: false,
        other: option.none as Option<string>,
      },
      fieldValidators: () => ({}),
    },
    {
      onSubmit: ({ needsHigherAmount, needsShorterTenor, other }) => {
        return submitFeedback(
          pipe(
            other,
            option.fold(
              () => ({
                reason: pipe(
                  these.fromOptions(
                    pipe(needsHigherAmount, option.fromPredicate(identity)),
                    pipe(needsShorterTenor, option.fromPredicate(identity))
                  ),
                  option.fold(
                    () => "NONE",
                    these.fold(
                      () => "HIGHER_AMOUNT",
                      () => "REPAYMENTPERIOD",
                      () => "BOTH"
                    )
                  )
                ),
              }),
              other =>
                ({
                  reason: "OTHER",
                  reasonDetails: other,
                } as ExitProcessInput)
            )
          )
        );
      },
    }
  );

  return (
    <Dialog
      variant="left"
      size="small"
      title={formatMessage(
        "PreApprovedLoanSaleConfigurator.feedbackModalTitle"
      )}
      onDismiss={option.some(props.onDismiss)}
      actions={[
        {
          label: formatMessage(
            "PreApprovedLoanSaleConfigurator.feedbackModalRefuse"
          ),
          action: props.onDismiss,
          variant: "text",
        },
        {
          label: formatMessage(
            "PreApprovedLoanSaleConfigurator.feedbackModalSubmit"
          ),
          action: handleSubmit,
          variant: "danger",
        },
      ]}
    >
      <Form>
        <FormSection>
          <FormRow type="full">
            <CheckboxField
              {...fieldProps("needsHigherAmount")}
              label={formatMessage(
                "PreApprovedLoanSaleConfigurator.feedbackModalHigherAmount"
              )}
              onChange={checked =>
                setValues({
                  needsHigherAmount: checked,
                  other: checked ? option.none : values.other,
                })
              }
              disabled={option.isSome(values.other)}
            />
          </FormRow>
          <FormRow type="full">
            <CheckboxField
              {...fieldProps("needsShorterTenor")}
              label={formatMessage(
                "PreApprovedLoanSaleConfigurator.feedbackModalShorterTenor"
              )}
              onChange={checked =>
                setValues({
                  needsShorterTenor: checked,
                  other: checked ? option.none : values.other,
                })
              }
              disabled={option.isSome(values.other)}
            />
          </FormRow>
          <FormRow type="full">
            <CheckboxField
              label={formatMessage(
                "PreApprovedLoanSaleConfigurator.feedbackModalOther"
              )}
              name="other"
              value={option.isSome(values.other)}
              onChange={checked =>
                setValues({
                  other: checked ? option.some("") : option.none,
                  needsHigherAmount: checked ? false : values.needsHigherAmount,
                  needsShorterTenor: checked ? false : values.needsShorterTenor,
                })
              }
              issues={option.none}
              onBlur={constVoid}
            />
          </FormRow>
          {option.isSome(values.other) && (
            <FormRow type="full">
              <TextareaField
                {...fieldProps("other")}
                autogrow={false}
                value={values.other.value}
                label={formatMessage(
                  "PreApprovedLoanSaleConfigurator.feedbackModalOther"
                )}
                placeholder={formatMessage(
                  "PreApprovedLoanSaleConfigurator.feedbackModalOtherPlaceholder"
                )}
                onChange={value => setValues({ other: option.some(value) })}
              />
            </FormRow>
          )}
        </FormSection>
      </Form>
      {showErrorBanner && (
        <>
          <Space units={8} />
          <Banner
            type="error"
            content={formatMessage(
              "PreApprovedLoanSaleConfigurator.feedbackModalSubmitError"
            )}
            actions={option.none}
            title={option.none}
          />
        </>
      )}
    </Dialog>
  );
}
