import {
  Box,
  Button,
  Card,
  Divider,
  Form,
  FormRow,
  FormSection,
  LoadingButton,
  NonNegativeInteger,
  PositiveInteger,
  Space,
  Stack,
  useForm,
  validators,
} from "design-system";
import { useFormatMessage } from "../../../intl";
import { boolean, either, option, taskEither } from "fp-ts";
import { ReadOnlyMoneyAmountField } from "../../../Common/ReadOnlyMoneyAmountField/ReadOnlyMoneyAmountField";
import { MoneyField } from "../../../Common/Price/MoneyField/MoneyField";
import { foldTenant, MoneyAmount, useCheckTenant } from "../../../globalDomain";
import { Option } from "fp-ts/Option";
import { useTenantCurrency } from "../../../Common/useTenantCurrency";
import { Dispatch, SetStateAction, useEffect, useMemo } from "react";
import {
  constFalse,
  constNull,
  constUndefined,
  constVoid,
  flow,
  pipe,
} from "fp-ts/function";
import { YesNoRadioGroupField } from "../../../Common/YesNoRadioGroup/YesNoRadioGroupField";
import { useAppContext } from "../../../useAppContext";
import { SubmitFormState } from "./ExpensesConfirmation";
import { IO } from "fp-ts/IO";
import { ExpensesCalculatorData } from "./api";
import { useUpdateEffect } from "react-use";

export interface CZFormData {
  otherMontlyExpenses: Option<number>;
  payAlimony: Option<never>;
  alimonyAmount: Option<never>;
}

export interface SKFormData {
  otherMontlyExpenses: Option<number>;
  payAlimony: Option<boolean>;
  alimonyAmount: Option<PositiveInteger>;
}

type Props = {
  minMontlyExpenses: MoneyAmount;
  regularMontlyExpenses: MoneyAmount;
  calculatorData: Option<ExpensesCalculatorData>;
  onCancel: IO<void>;
  setIsLiabilitiesDialogOpen: Dispatch<SetStateAction<boolean>>;
  setIsExpensesDialogOpen: Dispatch<SetStateAction<boolean>>;
  submitFormState: SubmitFormState;
  setSubmitFormState: Dispatch<SetStateAction<SubmitFormState>>;
  isCalculatorUsed: boolean;
};

export function ExpensesConfirmationForm(props: Props) {
  const formatMessage = useFormatMessage();
  const tenantCurrency = useTenantCurrency();
  const checkTenant = useCheckTenant();
  const showExpensesCalculator = checkTenant({
    CZ: () => option.isSome(props.calculatorData),
    SK: constFalse,
  });

  const {
    apiParameters: { tenant },
  } = useAppContext();

  const { fieldProps, handleSubmit, values, setValues, handleReset } = useForm(
    {
      initialValues: props.submitFormState,
      fieldValidators: ({ payAlimony }) => ({
        otherMontlyExpenses: validators.validator<
          Option<number>,
          Option<NonNegativeInteger>
        >(value =>
          pipe(
            value,
            option.fold(
              () => either.right(option.none),
              flow(
                NonNegativeInteger.decode,
                either.bimap(
                  () => formatMessage("Form.fieldError.nonNegativeInteger"),
                  option.some
                )
              )
            )
          )
        ),
        payAlimony: foldTenant(
          tenant,
          () => validators.definedNoExtract(formatMessage("GenericError")),
          constUndefined
        ),
        alimonyAmount: foldTenant(
          tenant,
          () =>
            validators.validator<Option<number>, Option<PositiveInteger>>(
              value =>
                pipe(
                  payAlimony,
                  option.fold(
                    () => either.right(option.none),
                    boolean.fold(
                      () => either.right(option.none),
                      () =>
                        pipe(
                          value,
                          option.fold(
                            () =>
                              either.left(
                                formatMessage("Form.fieldError.positiveInteger")
                              ),
                            flow(
                              PositiveInteger.decode,
                              either.bimap(
                                () =>
                                  formatMessage(
                                    "Form.fieldError.positiveInteger"
                                  ),
                                option.some
                              )
                            )
                          )
                        )
                    )
                  )
                )
            ),
          constUndefined
        ),
      }),
    },
    {
      onSubmit: data =>
        taskEither.fromIO(() =>
          foldTenant(
            tenant,
            () => props.setSubmitFormState(data as SKFormData),
            () => props.setSubmitFormState(data as CZFormData)
          )
        ),
    }
  );
  const totalActualExpenses = useMemo(() => {
    return {
      amount:
        props.minMontlyExpenses.amount +
        props.regularMontlyExpenses.amount +
        pipe(
          values.otherMontlyExpenses,
          option.getOrElse(() => 0)
        ) +
        pipe(
          values.alimonyAmount,
          option.getOrElse(() => 0)
        ),
      currency: props.minMontlyExpenses.currency,
    };
  }, [
    props.minMontlyExpenses.amount,
    props.regularMontlyExpenses.amount,
    values.otherMontlyExpenses,
    values.alimonyAmount,
    props.minMontlyExpenses.currency,
  ]);

  useEffect(() => {
    const payAlimony = pipe(
      fieldProps("payAlimony").value,
      option.getOrElse(constFalse)
    );

    if (!payAlimony) {
      fieldProps("alimonyAmount").onChange(option.none as Option<number>);
    }
  }, [fieldProps("payAlimony").value]);

  useUpdateEffect(handleReset, [JSON.stringify(props.submitFormState)]);

  return (
    <Card>
      <Form shrink>
        <FormSection
          heading={{
            title: formatMessage(
              "StandardLoan.ExpensesConfirmation.Form.title"
            ),
            subtitle: formatMessage(
              "StandardLoan.ExpensesConfirmation.Form.subtitle"
            ),
          }}
        >
          <FormRow type="full">
            <ReadOnlyMoneyAmountField
              size="x-small"
              value={props.minMontlyExpenses}
              label={formatMessage(
                "StandardLoan.ExpensesConfirmation.Panel.minimumMontlyExpensesLabel"
              )}
            />
          </FormRow>
          <FormRow type="1-1">
            <MoneyField
              {...fieldProps("otherMontlyExpenses")}
              disabled={props.isCalculatorUsed}
              min={0}
              max={999999999}
              name="regularMontlyExpenses"
              placeholder={formatMessage(
                "StandardLoan.ExpensesConfirmation.Form.otherMontlyExpensesPlaceholder"
              )}
              currency={tenantCurrency}
              label={formatMessage(
                "StandardLoan.ExpensesConfirmation.Form.otherMontlyExpensesLabel"
              )}
            />
            {showExpensesCalculator ? (
              <div style={{ display: "flex", flexGrow: 1, alignItems: "end" }}>
                <Button
                  label={formatMessage(
                    props.isCalculatorUsed
                      ? "StandardLoan.ExpensesConfirmation.Panel.editCalculator"
                      : "StandardLoan.ExpensesConfirmation.Panel.useCalculator"
                  )}
                  variant="link"
                  action={() => props.setIsExpensesDialogOpen(true)}
                />
              </div>
            ) : (
              <></>
            )}
          </FormRow>
          <FormRow type="full">
            <ReadOnlyMoneyAmountField
              size="x-small"
              value={props.regularMontlyExpenses}
              label={formatMessage(
                "StandardLoan.ExpensesConfirmation.Panel.regularMontlyExpensesLabel"
              )}
            />
          </FormRow>
          <FormRow type="full">
            <Box hAlignContent="left">
              <Button
                label={formatMessage(
                  "StandardLoan.ExpensesConfirmation.Panel.seeDetailCTA"
                )}
                variant="link"
                action={() => props.setIsLiabilitiesDialogOpen(true)}
              />
            </Box>
          </FormRow>
          {foldTenant(
            tenant,
            () => (
              <>
                <FormRow type="full">
                  <YesNoRadioGroupField
                    {...fieldProps("payAlimony")}
                    label={formatMessage(
                      "StandardLoan.ExpensesConfirmation.Form.alimonyLabel"
                    )}
                    description={formatMessage(
                      "StandardLoan.ExpensesConfirmation.Form.alimonyDescription"
                    )}
                    onBlur={constVoid}
                  />
                </FormRow>
                <Space units={8} />
                {pipe(
                  values.payAlimony,
                  option.fold(constNull, value =>
                    pipe(
                      value,
                      boolean.fold(constNull, () => (
                        <FormRow type="1-1">
                          <MoneyField
                            {...fieldProps("alimonyAmount")}
                            min={1}
                            max={999999999}
                            currency={tenantCurrency}
                            placeholder={formatMessage(
                              "StandardLoan.ExpensesConfirmation.Form.alimonyAmountPlaceholder"
                            )}
                            label={formatMessage(
                              "StandardLoan.ExpensesConfirmation.Form.alimonyAmountLabel"
                            )}
                          />
                          <></>
                        </FormRow>
                      ))
                    )
                  )
                )}
              </>
            ),
            constNull
          )}
          <FormRow type="full">
            <Divider />
          </FormRow>
          <FormRow type="full">
            <ReadOnlyMoneyAmountField
              size="x-small"
              value={totalActualExpenses}
              label={formatMessage(
                "StandardLoan.ExpensesConfirmation.Panel.totalActualExpenses"
              )}
            />
          </FormRow>
          <FormRow type="full">
            <Stack hAlignContent="right">
              <Button
                label={formatMessage(
                  "StandardLoan.ExpensesConfirmation.Panel.editCancel"
                )}
                variant="link"
                action={() => {
                  setValues(props.submitFormState);
                  props.onCancel();
                }}
              />
              <LoadingButton
                action={handleSubmit}
                size="default"
                variant="primary"
                labels={{
                  normal: formatMessage("Save"),
                  success: formatMessage("Save"),
                  loading: formatMessage("Save"),
                  error: formatMessage("Save"),
                }}
              />
            </Stack>
          </FormRow>
        </FormSection>
      </Form>
    </Card>
  );
}
