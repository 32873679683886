import { createContext, useContext, useEffect, useState } from "react";
import { ExpensesCalculatorData } from "../api";
import { constVoid } from "fp-ts/function";

export const emptyExpenses: ExpensesCalculatorData = {
  regularExpenses: {
    customExpensesFields: [],
    otherAmount: 0,
    pensionAmount: 0,
    savingAmount: 0,
    fusesAmount: 0,
  },
  necessaryExpenses: {
    customExpensesFields: [],
    otherAmount: 0,
    transportAmount: 0,
    repairsAmount: 0,
    housingAmount: 0,
    healthAmount: 0,
    entertainmentAmount: 0,
    educationAmount: 0,
    clothesAmount: 0,
    cateringAmount: 0,
    alimonyAmount: 0,
    commonPurchasesAmount: 0,
  },
};

export interface ExpensesCalculatorContext {
  regularSum: number;
  setRegularSum: (sum: number) => unknown;
  necessarySum: number;
  setNecessarySum: (sum: number) => unknown;
  totalSum: number;
  calculatorData: ExpensesCalculatorData;
  setCalculatorData: React.Dispatch<
    React.SetStateAction<ExpensesCalculatorData>
  >;
}

export const ExpensesCalculatorContext = createContext<ExpensesCalculatorContext>(
  {
    regularSum: 0,
    setRegularSum: (_: number) => {},
    necessarySum: 0,
    setNecessarySum: (_: number) => {},
    totalSum: 0,
    calculatorData: emptyExpenses,
    setCalculatorData: constVoid,
  }
);

type ExpensesCalculatorProviderProps = {
  initialExpenses: ExpensesCalculatorData;
  children: JSX.Element;
};
export const ExpensesCalculatorProvider = (
  props: ExpensesCalculatorProviderProps
) => {
  const [regularSum, setRegularSum] = useState(0);
  const [necessarySum, setNecessarySum] = useState(0);
  const [totalSum, setTotalSum] = useState(0);

  const [calculatorData, setCalculatorData] = useState<ExpensesCalculatorData>(
    props.initialExpenses
  );

  useEffect(() => {
    setTotalSum(regularSum + necessarySum);
  }, [regularSum, necessarySum]);

  return (
    <ExpensesCalculatorContext.Provider
      value={{
        regularSum,
        setRegularSum,
        necessarySum,
        setNecessarySum,
        totalSum,
        calculatorData,
        setCalculatorData,
      }}
    >
      {props.children}
    </ExpensesCalculatorContext.Provider>
  );
};

export function useExpensesCalculatorContext() {
  const context = useContext(ExpensesCalculatorContext);

  if (!context) {
    throw new Error("UpdateCostumerOfferContext not provided");
  }

  return context;
}
