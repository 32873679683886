import {
  DropdownOption,
  ErrorBanner,
  ReadOnlyField,
  LocalizedString,
  ComputedFieldProps,
} from "design-system";

import { constNull, constVoid, flow, pipe } from "fp-ts/function";
import { Option } from "fp-ts/Option";
import { useQuery } from "../useAPI";
import {
  dropdownOptionToValue,
  selectedDropdownOption,
} from "../Common/selectDropdownOption";
import * as api from "../StandardLoan/api";
import * as remoteData from "../RemoteData";
import { option } from "fp-ts";
import { LocaleKey, useFormatMessage } from "../intl";
import { useEffect } from "react";

import { DropdownField } from "../Common/DropdownField/DropdownField";

type Props = ComputedFieldProps<Option<LocalizedString>> & {
  onFinishLoading: () => unknown;
};

export function DisbursementAccountsField(props: Props) {
  const formatMessage = useFormatMessage();
  const [disbursementAccounts] = useQuery(api.getDisbursementAccounts);
  const label = formatMessage(
    "PreApprovedLoanSaleRecap.disbursementAccounts.label"
  );

  useEffect(
    () =>
      pipe(
        disbursementAccounts,
        remoteData.fold(
          constVoid,
          () => {
            props.onFinishLoading();
          },
          accounts => {
            props.onFinishLoading();
            if (accounts.length === 1) {
              props.onChange(option.some(accounts[0]));
            }
          }
        )
      ),
    [disbursementAccounts]
  );

  return pipe(
    disbursementAccounts,
    remoteData.fold(
      constNull,
      response => {
        if ("code" in response) {
          return (
            <ErrorBanner>
              {formatMessage(
                ("AccountCollectionByClient." + response.code) as LocaleKey
              )}
            </ErrorBanner>
          );
        } else {
          return <ErrorBanner>{formatMessage("GenericError")}</ErrorBanner>;
        }
      },
      accounts => {
        if (accounts.length === 1) {
          return (
            <ReadOnlyField value={accounts[0]} label={label} size="medium" />
          );
        } else {
          const options = accounts.map(accountName => ({
            label: accountName,
            value: accountName,
          }));

          const value = selectedDropdownOption(props.value, options);

          const onChange: (
            option: Option<DropdownOption<LocalizedString>>
          ) => void = flow(dropdownOptionToValue, props.onChange);

          return (
            <DropdownField
              {...props}
              value={value}
              onChange={onChange}
              options={options}
              label={label}
              placeholder={formatMessage(
                "PreApprovedLoanSaleRecap.disbursementAccounts.placeholder"
              )}
              searchable={false}
              clearable={false}
            />
          );
        }
      }
    )
  );
}
