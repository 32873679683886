import { FeedbackBlock } from "design-system";
import { option } from "fp-ts";
import { pipe } from "fp-ts/function";
import { LocaleKey, useFormatMessage } from "../../intl";
import { UserValidation } from "../../Common/UserValidation/UserValidation";
import {
  foldFlowType,
  RefuseExistingClientReason,
  StandardLoanFlowType,
} from "../domain";
import { MainContent } from "../../Common/MainContent";

type Props = {
  onExit: () => unknown;
  flowType: StandardLoanFlowType;
  reason: RefuseExistingClientReason;
};

export function ExistingClient(props: Props) {
  const formatMessage = useFormatMessage();

  const renderNon3PBlock = (
    <FeedbackBlock
      type="negative"
      size="large"
      heading={formatMessage("StandardLoan.ExistingClient.title")}
      subheading={option.some(formatMessage(getSubtitleByReason(props.reason)))}
      actions={[
        {
          variant: "primary",
          label: formatMessage(
            getButtonLabelByReason(props.reason, props.flowType === "PWSRemote")
          ),
          action: props.onExit,
        },
      ]}
    />
  );

  return (
    <MainContent>
      {pipe(
        props.flowType,
        foldFlowType({
          when3P: () => (
            <UserValidation
              isModal={true}
              dialogTitle={formatMessage("StandardLoan.ExistingClient.title3P")}
              onDialogDismiss={option.some(props.onExit)}
              onDialogExit={option.some(props.onExit)}
              validateUser={details =>
                details.hasPhoneNumber &&
                details.hasEmailAddress &&
                details.hasPasswordForCommunication
              }
            >
              <FeedbackBlock
                type="negative"
                size="large"
                heading={formatMessage("StandardLoan.ExistingClient.title3P")}
                subheading={option.some(
                  formatMessage("StandardLoan.ExistingClient.subTitle3P")
                )}
                actions={[
                  {
                    variant: "text",
                    label: formatMessage(
                      "StandardLoan.ExistingClient.button3P"
                    ),
                    action: props.onExit,
                  },
                ]}
              />
            </UserValidation>
          ),
          whenInPerson: () => renderNon3PBlock,
          whenTLSAgent: () => renderNon3PBlock,
          whenHomeBanking: () => renderNon3PBlock,
          whenSmartBanking: () => renderNon3PBlock,
          whenPWSRemote: () => renderNon3PBlock,
        })
      )}
    </MainContent>
  );
}

function getSubtitleByReason(reason: RefuseExistingClientReason): LocaleKey {
  switch (reason) {
    case "IsExisting":
      return "StandardLoan.ExistingClient.subTitle";
    case "IsPersonalNumberMatch":
      return "StandardLoan.PersonalNumberMatch.subTitle";
    case "HasUserId":
    case "IsMultipleUser":
      return "StandardLoan.ExistingClient.multipleUser.subTitle";
  }
}

function getButtonLabelByReason(
  reason: RefuseExistingClientReason,
  isPWSRemote: boolean
): LocaleKey {
  switch (reason) {
    case "IsExisting":
      return isPWSRemote
        ? "StandardLoan.ExistingClient.PWS.button"
        : "StandardLoan.ExistingClient.button";
    case "IsPersonalNumberMatch":
      return "StandardLoan.PersonalNumberMatch.button";
    case "HasUserId":
    case "IsMultipleUser":
      return "StandardLoan.ExistingClient.multipleUser.button";
  }
}
