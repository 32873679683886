import * as t from "io-ts";
import { CreditType, LoanPurpose } from "../../../../MortgageDashboard/domain";
import { eq } from "fp-ts";
import { constFalse, constTrue } from "fp-ts/function";
import { apiCall } from "../../../../APICall";
import {
  DateFromSQLString,
  MoneyAmount,
  MonthYear,
  optionFromUndefined,
} from "../../../../globalDomain";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";

const AmountLimit = t.type({ min: t.number, max: t.number });
export type AmountLimit = t.TypeOf<typeof AmountLimit>;

export const OriginalLoanPurpose = t.keyof({
  PURCHASE: true,
  RECONSTRUCTION: true,
  CONSTRUCTION: true,
  REVERSE_REPAYMENT: true,
  NOT_PURPOSE: true,
});
export type OriginalLoanPurpose = t.TypeOf<typeof OriginalLoanPurpose>;

export const RefinanceLoansForLivingSelection = t.keyof({
  REFINANCE_WITH_LOANS_FOR_LIVING_TRUE: true,
  REFINANCE_WITH_LOANS_FOR_LIVING_FALSE: true,
  REFINANCE_WITH_ANY_LOANS_FOR_LIVING: true,
});
export type RefinanceLoansForLivingSelection = t.TypeOf<
  typeof RefinanceLoansForLivingSelection
>;

export const ConsumerLoanOrMortgage = t.type(
  {
    creditType: optionFromUndefined(CreditType),
    currentBalance: optionFromUndefined(MoneyAmount),
    finalDate: optionFromUndefined(DateFromSQLString),
    loanPurpose: optionFromUndefined(LoanPurpose),
    monthlyInstallment: optionFromUndefined(MoneyAmount),
    approvedCreditAmount: optionFromUndefined(MoneyAmount),
    canBeRefinanced: optionFromUndefined(t.boolean),
    sharedCreditCoApplicantName: optionFromUndefined(NonEmptyString),
    loansForLiving: optionFromUndefined(t.boolean),
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    propertyOwner: optionFromUndefined(t.boolean),
    contractNumber: optionFromUndefined(t.string),
    originalLoanPurpose: optionFromUndefined(OriginalLoanPurpose),
    displayOriginalLoanPurpose: optionFromUndefined(t.boolean),
    displayLoansForLiving: optionFromUndefined(t.boolean),
    loansForLivingDefaultValue: optionFromUndefined(t.boolean),
    refinanceLoansForLivingSelection: optionFromUndefined(
      RefinanceLoansForLivingSelection
    ),
    usedForRefinance: optionFromUndefined(t.boolean),
    recordId: NonEmptyString,
    manuallyAdded: t.boolean,
    provider: optionFromUndefined(NonEmptyString),
    originalLoanAmount: optionFromUndefined(MoneyAmount),
  },
  "ConsumerLoanOrMortgage"
);
export type ConsumerLoanOrMortgage = t.TypeOf<typeof ConsumerLoanOrMortgage>;

export const AddConsumerLoanOrMortgageInput = t.type(
  {
    creditType: CreditType,
    currentBalance: MoneyAmount,
    finalDateOfTheLoan: MonthYear,
    loanPurpose: LoanPurpose,
    monthlyInstallment: MoneyAmount,
    originalLoanAmount: MoneyAmount,
    usedForRefinance: optionFromUndefined(t.boolean),
  },
  "ConsumerLoanOrMortgageInput"
);
export type AddConsumerLoanOrMortgageInput = t.TypeOf<
  typeof AddConsumerLoanOrMortgageInput
>;

export const EditConsumerLoanOrMortgageInput = t.type(
  {
    creditType: CreditType,
    currentBalance: MoneyAmount,
    finalDate: MonthYear,
    loanPurpose: LoanPurpose,
    monthlyInstallmentAmount: MoneyAmount,
    approvedCreditAmount: MoneyAmount,
    recordId: NonEmptyString,
  },
  "EditConsumerLoanOrMortgageInput"
);
export type EditConsumerLoanOrMortgageInput = t.TypeOf<
  typeof EditConsumerLoanOrMortgageInput
>;

export const Overdraft = t.type(
  {
    currentBalance: optionFromUndefined(MoneyAmount),
    overdraftLimit: optionFromUndefined(MoneyAmount),
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    changeLimit: optionFromUndefined(t.boolean),
    contractNumber: optionFromUndefined(t.string),
    newOverdraftLimit: optionFromUndefined(MoneyAmount),
    manuallyAdded: t.boolean,
    recordId: NonEmptyString,
    provider: optionFromUndefined(NonEmptyString),
    usedForRefinance: optionFromUndefined(t.boolean),
    includedInBonita: optionFromUndefined(t.boolean),
  },
  "OverdraftAdd"
);
export type Overdraft = t.TypeOf<typeof Overdraft>;

export const OverdraftAddInput = t.type(
  {
    currentBalance: MoneyAmount,
    overdraftLimit: MoneyAmount,
  },
  "OverdraftAddInput"
);
export type OverdraftAddInput = t.TypeOf<typeof OverdraftAddInput>;

export const OverdraftEditInput = t.type(
  {
    currentBalance: MoneyAmount,
    overdraftLimit: MoneyAmount,
    recordId: NonEmptyString,
  },
  "OverdraftEditInput"
);
export type OverdraftEditInput = t.TypeOf<typeof OverdraftEditInput>;

export const CreditCard = t.type(
  {
    currentBalance: optionFromUndefined(MoneyAmount),
    creditCardLimit: optionFromUndefined(MoneyAmount),
    repaidBeforeDisbursement: optionFromUndefined(t.boolean),
    changeLimit: optionFromUndefined(t.boolean),
    contractNumber: optionFromUndefined(t.string),
    newOverdraftLimit: optionFromUndefined(MoneyAmount),
    manuallyAdded: t.boolean,
    recordId: NonEmptyString,
    provider: optionFromUndefined(NonEmptyString),
    newCreditCardLimit: optionFromUndefined(MoneyAmount),
    usedForRefinance: optionFromUndefined(t.boolean),
    includedInBonita: optionFromUndefined(t.boolean),
  },
  "OverdraftAdd"
);
export type CreditCard = t.TypeOf<typeof CreditCard>;

export const CreditCardAddInput = t.type(
  {
    currentBalance: MoneyAmount,
    creditCardLimit: MoneyAmount,
  },
  "OverdraftAddInput"
);
export type CreditCardAddInput = t.TypeOf<typeof OverdraftAddInput>;

export const CreditCardEditInput = t.type(
  {
    currentBalance: MoneyAmount,
    creditCardLimit: MoneyAmount,
    recordId: NonEmptyString,
  },
  "CreditCardEditInput"
);
export type CreditCardEditInput = t.TypeOf<typeof CreditCardEditInput>;

export const GetAllLiabilitiesOutput = t.type(
  {
    mtgAndClMonthlyInstallmentLimit: AmountLimit,
    mtgAndClCurrentBalanceLimit: AmountLimit,
    mtgAndClApprovedLoanAmountLimit: AmountLimit,
    ccAndOvdCurrentBalanceLimit: AmountLimit,
    ccAndOvdLimit: AmountLimit,
    consumerLoansOrMortgagesList: t.array(ConsumerLoanOrMortgage),
    overdraftsList: t.array(Overdraft),
    creditCardsList: t.array(CreditCard),
  },
  "GetAllLiabilitiesOutput"
);

export type GetAllLiabilitiesOutput = t.TypeOf<typeof GetAllLiabilitiesOutput>;

export const getAllLiabilities = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "liabilities",
    "getAllLiabilities",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
  outputCodec: GetAllLiabilitiesOutput,
});

export const start = apiCall({
  path: ["packages", "loans", "standard-loan", "liabilities", "start"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constTrue),
});

const DeleteLiabilityRecordInput = t.type(
  { recordId: NonEmptyString },
  "DeleteLiabilityRecordInput"
);

export const deleteLiabilityRecord = apiCall({
  path: ["packages", "loans", "standard-loan", "liabilities", "delete"],
  inputCodec: DeleteLiabilityRecordInput,
  inputEq: eq.fromEquals(constFalse),
});

export const cancelWithoutSaving = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "liabilities",
    "cancelWithoutSaving",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

export const saveAndUpdate = apiCall({
  path: ["packages", "loans", "standard-loan", "liabilities", "save"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
});

export const addConsumerLoanOrMortgage = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "liabilities",
    "addConsumerLoanOrMortgage",
  ],
  inputCodec: AddConsumerLoanOrMortgageInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ConsumerLoanOrMortgage,
});

export const editConsumerLoanOrMortgage = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "liabilities",
    "editConsumerLoanOrMortgage",
  ],
  inputCodec: EditConsumerLoanOrMortgageInput,
  outputCodec: ConsumerLoanOrMortgage,
  inputEq: eq.fromEquals(constFalse),
});

export const addOverdraft = apiCall({
  path: ["packages", "loans", "standard-loan", "liabilities", "addOverdraft"],
  inputCodec: OverdraftAddInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: Overdraft,
});

export const editOverdraft = apiCall({
  path: ["packages", "loans", "standard-loan", "liabilities", "editOverdraft"],
  inputCodec: OverdraftEditInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: Overdraft,
});

export const addCreditCard = apiCall({
  path: ["packages", "loans", "standard-loan", "liabilities", "addCreditCard"],
  inputCodec: CreditCardAddInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CreditCard,
});

export const editCreditCard = apiCall({
  path: ["packages", "loans", "standard-loan", "liabilities", "editCreditCard"],
  inputCodec: CreditCardEditInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: CreditCard,
});
