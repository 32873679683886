import { FeedbackBlock } from "design-system";
import { option } from "fp-ts";
import { IO } from "fp-ts/IO";
import { useFormatMessage } from "../../intl";
import { MainContent } from "../../Common/MainContent";

type Props = {
  onExit: IO<unknown>;
};

export function ExistingApplication3PError(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <MainContent>
      <FeedbackBlock
        type="negative"
        size="large"
        heading={formatMessage("StandardLoan.ExistingApplication.title3P")}
        subheading={option.some(
          formatMessage("StandardLoan.ExistingApplication.subTitle3P")
        )}
        actions={[
          {
            variant: "primary",
            label: formatMessage("StandardLoan.ExistingApplication.button3P"),
            action: props.onExit,
          },
        ]}
      />
    </MainContent>
  );
}
