import {
  Body,
  Box,
  CheckboxField,
  ErrorBanner,
  FormRow,
  FormSection,
  Loader,
  LoadingButton,
  Space,
  Stack,
  useForm,
  validators,
} from "design-system";
import { useFormatMessage } from "../../../intl";
import { constant, constNull, pipe } from "fp-ts/function";
import { array, boolean, eq, option, record, taskEither } from "fp-ts";
import { ReadOnlyExpensesConfirmation } from "./ReadOnlyExpensesConfirmation";
import { useEffect, useState } from "react";
import { ExpensesConfirmationForm } from "./ExpenseConfirmationForm";
import { MoneyAmount } from "../../../globalDomain";
import { Option } from "fp-ts/Option";
import { useCommand, usePollingEffect, useQuery } from "../../../useAPI";
import {
  BonitaStatus,
  ExpensesCalculatorData,
  getExpenses,
  LfDecision,
  submitExpenses,
  SubmitExpensesInput,
} from "./api";
import * as remoteData from "../../../RemoteData";
import { CurrentLiabilitiesDialog } from "./CurrentLiabilitiesDialog/CurrentLiabilitiesDialog";
import { ExpensesCalculatorDialog } from "./ExpensesCalculator/ExpensesCalculatorDialog";
import { getBonitaStatus } from "../../api";

export type LfDecisionReceived = Omit<LfDecision, "Pending">;

type Props = {
  onExpensesConfirmationNext: (
    needAdditionalIncomeStep: boolean,
    lfDecision: LfDecisionReceived
  ) => void;
  setIsHeaderReadonly?: (r: boolean) => void;
};

export type SubmitFormState = {
  otherMontlyExpenses: Option<number>;
  payAlimony: Option<boolean>;
  alimonyAmount: Option<number>;
};

export function ExpensesConfirmation(props: Props) {
  const [submitFormState, setSubmitFormState] = useState<SubmitFormState>({
    otherMontlyExpenses: option.none as Option<number>,
    payAlimony: option.none as Option<boolean>,
    alimonyAmount: option.none as Option<number>,
  });
  const [, setShowError] = useState<boolean>(false);
  const [isLiabilitiesDialogOpen, setIsLiabilitiesDialogOpen] = useState(false);
  const [isExpensesDialogOpen, setIsExpensesDialogOpen] = useState(false);
  const formatMessage = useFormatMessage();
  const [readOnly, setReadOnly] = useState(true);
  const [loading, setLoading] = useState(true);
  const [canPoll, setCanPoll] = useState(false);
  const [bonitaStatus, setBonitaStatus] = useState<BonitaStatus>("NOT_STARTED");

  const restartPoll = usePollingEffect(getBonitaStatus, {
    intervalMS: 5000,
    disabled: !canPoll,
    shouldPollingContinue: val => val.bonitaStatus === "PENDING",
    onError: () => setShowError(true),
    onSuccess: response => {
      setBonitaStatus(response.bonitaStatus);
      if (
        response.bonitaStatus === "DONE" &&
        option.isSome(response.submitExpensesResponse)
      ) {
        const expensesResponse = response.submitExpensesResponse.value;
        if (expensesResponse.lfDecision !== "Pending") {
          setCanPoll(false);
          props.onExpensesConfirmationNext(
            expensesResponse.needAdditionalIncomeStep,
            expensesResponse.lfDecision
          );
        }
      }
      if (response.bonitaStatus === "ERROR") {
        setShowError(true);
      }
    },
  });

  const [expenses, refreshExpenses] = useQuery(getExpenses);

  const submitExpensesForm = useCommand(submitExpenses);

  useEffect(() => {
    if (props.setIsHeaderReadonly) {
      props.setIsHeaderReadonly(readOnly);
    }
  }, [readOnly]);

  const { fieldProps, handleSubmit } = useForm(
    {
      initialValues: {
        disclaimerCheckbox: false,
      },
      fieldValidators: () => ({
        disclaimerCheckbox: validators.checked(
          formatMessage(
            "StandardLoan.ExpensesConfirmation.Form.checkboxFieldError"
          )
        ),
      }),
    },
    {
      onSubmit: ({ disclaimerCheckbox }) =>
        pipe(
          submitExpensesForm({
            disclaimerCheckbox,

            ...submitFormState,
          } as SubmitExpensesInput),
          taskEither.chain(() =>
            taskEither.fromIO(() => {
              setBonitaStatus(() => "PENDING");
              setCanPoll(true);
            })
          )
        ),
    }
  );

  useEffect(() => {
    if (canPoll) {
      restartPoll();
    }
  }, [canPoll]);

  const foldOtherExpenses: (
    formStateOtherExpenses: Option<number>,
    remoteOtherExpenses: Option<MoneyAmount>
  ) => Option<number> = (formStateOtherExpenses, remoteOtherExpenses) =>
    pipe(
      formStateOtherExpenses,
      option.fold(
        () =>
          pipe(
            remoteOtherExpenses,
            option.filterMap(v =>
              pipe(
                v.amount,
                option.fromPredicate(amount => !eq.eqNumber.equals(amount, 0))
              )
            )
          ),
        () => formStateOtherExpenses
      )
    );

  const foldAlimonyAmount: (
    formStateAlimonyAmount: Option<number>,
    remoteAlimonyAmount: Option<MoneyAmount>
  ) => Option<number> = (formStateAlimonyAmount, remoteAlimonyAmount) =>
    pipe(
      formStateAlimonyAmount,
      option.fold(
        () =>
          pipe(
            remoteAlimonyAmount,
            option.filterMap(v =>
              pipe(
                v.amount,
                option.fromPredicate(amount => !eq.eqNumber.equals(amount, 0))
              )
            )
          ),
        () => formStateAlimonyAmount
      )
    );

  const foldPayAlimony: (
    formStatePayAlimony: Option<boolean>,
    remotePayAlimony: Option<boolean>
  ) => Option<boolean> = (formStatePayAlimony, remotePayAlimony) => {
    return pipe(
      formStatePayAlimony,
      option.fold(
        () => remotePayAlimony,
        () => formStatePayAlimony
      )
    );
  };

  const otherNecessaryExpensesCalculatorValue = (
    calculatorData: Option<ExpensesCalculatorData>
  ) =>
    pipe(
      calculatorData,
      option.map(val => {
        const customFieldsSum = pipe(
          val.necessaryExpenses.customExpensesFields,
          array.reduce(0, (acc, cur) => acc + cur.value)
        );
        const fieldsSum = pipe(
          val.necessaryExpenses,
          record.reduce(
            0,
            (acc, cur) => (Array.isArray(cur) ? acc : acc + (cur as number)) // should test for number instead of array... somehow
          )
        );

        return customFieldsSum + fieldsSum;
      }),
      option.getOrElse(constant(0))
    );

  const getDifferenceFromNecessaryAndEstimated = (
    calculatorData: Option<ExpensesCalculatorData>,
    minMontlyExpenses: number
  ) =>
    otherNecessaryExpensesCalculatorValue(calculatorData) - minMontlyExpenses;

  const updateLoading = (newVal: boolean) =>
    newVal !== loading && setLoading(newVal);
  pipe(
    expenses,
    remoteData.fold(
      () => updateLoading(true),
      () => updateLoading(false),
      (_, loading) => updateLoading(loading)
    )
  );
  if (bonitaStatus === "PENDING") {
    return (
      <Box hAlignContent="center">
        <Loader />
      </Box>
    );
  }
  return (
    <Box column grow shrink>
      <Stack column grow shrink>
        <Body size="medium" weight="regular">
          {formatMessage("StandardLoan.ExpensesConfirmation.Panel.description")}
        </Body>
        <Space units={10} />
        {loading ? (
          <Loader />
        ) : (
          pipe(
            readOnly,
            boolean.fold(
              () =>
                pipe(
                  expenses,
                  remoteData.fold(
                    () => <Loader />,
                    () => (
                      <Box hAlignContent="center">
                        <ErrorBanner children={formatMessage("GenericError")} />
                      </Box>
                    ),
                    data => (
                      <ExpensesConfirmationForm
                        setIsLiabilitiesDialogOpen={setIsLiabilitiesDialogOpen}
                        setIsExpensesDialogOpen={setIsExpensesDialogOpen}
                        submitFormState={{
                          ...submitFormState,
                          otherMontlyExpenses: pipe(
                            data.isCalculatorUsed,
                            boolean.fold(
                              () =>
                                foldOtherExpenses(
                                  submitFormState.otherMontlyExpenses,
                                  data.otherRegularExpenses
                                ),
                              () =>
                                pipe(
                                  data.minMontlyExpenses.amount >=
                                    otherNecessaryExpensesCalculatorValue(
                                      data.expensesCalculator
                                    ),
                                  boolean.fold(
                                    () =>
                                      getDifferenceFromNecessaryAndEstimated(
                                        data.expensesCalculator,
                                        data.minMontlyExpenses.amount
                                      ),
                                    constant(0)
                                  ),
                                  option.some
                                )
                            )
                          ),

                          alimonyAmount: foldAlimonyAmount(
                            submitFormState.alimonyAmount,
                            data.alimonyExpenses
                          ),
                          payAlimony: foldPayAlimony(
                            submitFormState.payAlimony,
                            pipe(
                              data.alimonyExpenses,
                              option.fold(
                                () => option.some(false),
                                v => option.some(v.amount > 0)
                              )
                            )
                          ),
                        }}
                        setSubmitFormState={values => {
                          setSubmitFormState(values);
                          setReadOnly(true);
                        }}
                        onCancel={() => setReadOnly(true)}
                        minMontlyExpenses={data.minMontlyExpenses}
                        regularMontlyExpenses={data.regularMontlyExpenses}
                        calculatorData={data.expensesCalculator}
                        isCalculatorUsed={data.isCalculatorUsed}
                      />
                    )
                  )
                ),
              () =>
                pipe(
                  expenses,
                  remoteData.fold(
                    constant(<Loader />),
                    () => (
                      <Box hAlignContent="center">
                        <ErrorBanner children={formatMessage("GenericError")} />
                      </Box>
                    ),
                    data => (
                      <ReadOnlyExpensesConfirmation
                        minMontlyExpenses={data.minMontlyExpenses}
                        regularMontlyExpenses={data.regularMontlyExpenses}
                        otherExpenses={foldOtherExpenses(
                          submitFormState.otherMontlyExpenses,
                          data.otherRegularExpenses
                        )}
                        alimony={foldAlimonyAmount(
                          submitFormState.alimonyAmount,
                          data.alimonyExpenses
                        )}
                        setReadOnly={setReadOnly}
                      />
                    )
                  )
                )
            )
          )
        )}
        <Space units={10} />
        {pipe(
          expenses,
          remoteData.fold(constNull, constNull, () => (
            <Box grow shrink column>
              <FormSection>
                <FormRow type="full">
                  <CheckboxField
                    label={formatMessage(
                      "StandardLoan.ExpensesConfirmation.Panel.checkbox"
                    )}
                    {...fieldProps("disclaimerCheckbox")}
                  />
                </FormRow>
              </FormSection>
              <Box hAlignContent="right" column>
                <Space units={10} />
                <LoadingButton
                  disabled={!readOnly}
                  type="submit"
                  variant="primary"
                  labels={{
                    normal: formatMessage("Confirm.buttonLabel"),
                    success: formatMessage("Confirm.buttonLabel"),
                    loading: formatMessage("Loading"),
                    error: formatMessage("Confirm.buttonLabel"),
                  }}
                  size="default"
                  action={handleSubmit}
                />
              </Box>
            </Box>
          ))
        )}
      </Stack>
      {pipe(
        isLiabilitiesDialogOpen,
        boolean.fold(constNull, () => (
          <CurrentLiabilitiesDialog
            setIsLiabilitiesDialogOpen={isOpen => {
              !isOpen && refreshExpenses();
              setIsLiabilitiesDialogOpen(isOpen);
            }}
          />
        ))
      )}
      {pipe(
        isExpensesDialogOpen,
        boolean.fold(constNull, () =>
          pipe(
            expenses,
            remoteData.fold(constNull, constNull, data =>
              option.isSome(data.expensesCalculator) ? (
                <ExpensesCalculatorDialog
                  onSaved={() => {
                    refreshExpenses();
                    setIsExpensesDialogOpen(false);
                  }}
                  onCancel={() => setIsExpensesDialogOpen(false)}
                  calculatorData={data.expensesCalculator.value}
                  minMonthlyExpenses={data.minMontlyExpenses}
                />
              ) : null
            )
          )
        )
      )}
    </Box>
  );
}
