import { Option } from "fp-ts/Option";
import { LocaleKey, useFormatMessage } from "../../../../intl";
import { useTenantCurrency } from "../../../../Common/useTenantCurrency";
import {
  ComputedFieldProps,
  FormRow,
  FormSection,
  unsafeLocalizedString,
} from "design-system";
import { MoneyField } from "../../../../Common/Price/MoneyField/MoneyField";
import { pipe } from "fp-ts/function";
import { array } from "fp-ts";
import { ComputedFieldPropsExtended } from "./ExpensesCalculator";

type ExpensesSubFormProps = {
  fields: ComputedFieldPropsExtended<Option<number>>[];
};

export function ExpensesSubForm(props: ExpensesSubFormProps) {
  const formatMessage = useFormatMessage();
  const tenantCurrency = useTenantCurrency();
  const fieldProps = props.fields;
  const getMoneyField = (
    field: ComputedFieldProps<Option<number>>,
    label: LocaleKey
  ) => (
    <MoneyField
      {...field}
      decimalDigits={0}
      min={0}
      max={999999999}
      currency={tenantCurrency}
      label={formatMessage(label)}
      placeholder={unsafeLocalizedString("0")}
    />
  );

  return (
    <FormSection>
      {pipe(
        fieldProps,
        array.chunksOf(2),
        array.map(arr => {
          if (arr.length === 1) {
            return (
              <FormRow type={"1-1"}>
                {getMoneyField(arr[0], arr[0].translationKey)}
                <></>
              </FormRow>
            );
          }
          return (
            <FormRow type={"1-1"}>
              {getMoneyField(arr[0], arr[0].translationKey)}
              {getMoneyField(arr[1], arr[1].translationKey)}
            </FormRow>
          );
        })
      )}
    </FormSection>
  );
}
