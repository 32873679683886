import { option, taskEither } from "fp-ts";
import { useFormatMessage } from "../../../../intl";
import { Dialog } from "design-system";
import { CurrentLiabilitiesDialogContent } from "./CurrentLiabilitiesDialogContent";
import { Dispatch, SetStateAction } from "react";
import { useCommand } from "../../../../useAPI";
import { constVoid, pipe } from "fp-ts/function";
import * as standardLoanApi from "./api";

type Props = {
  setIsLiabilitiesDialogOpen: Dispatch<SetStateAction<boolean>>;
};

export function CurrentLiabilitiesDialog(props: Props) {
  const formatMessage = useFormatMessage();
  const cancelWithoutSavingStandardLoan = useCommand(
    standardLoanApi.cancelWithoutSaving
  );
  const handleClose = () => props.setIsLiabilitiesDialogOpen(false);

  return (
    <Dialog
      title={formatMessage("StandardLoan.ExpensesConfirmation.Dialog.title")}
      size="small"
      actions={[]}
      onDismiss={option.some(
        pipe(
          cancelWithoutSavingStandardLoan(),
          taskEither.bimap(constVoid, handleClose)
        )
      )}
      variant="left"
    >
      <CurrentLiabilitiesDialogContent onClose={handleClose} />
    </Dialog>
  );
}
