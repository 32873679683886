import { Box, Loader } from "design-system";
import { useQuery } from "../../useAPI";
import * as apis from "../OfferList/api";
import { pipe } from "fp-ts/function";
import * as remoteData from "../../RemoteData";
import * as api from "../api";

interface Props {
  onFailure: () => unknown;
  onContinue: (data: api.GenericLoanResponseOutput) => unknown;
}

export const SaveCustomerOfferSuspense = (props: Props) => {
  const [saveCustomerSelectedOffer] = useQuery(apis.saveCustomerSelectedOffer);

  return pipe(
    saveCustomerSelectedOffer,
    remoteData.fold(
      () => (
        <Box hAlignContent="center">
          <Loader />
        </Box>
      ),
      () => <>{props.onFailure()}</>,
      data => <>{props.onContinue(data)}</>
    )
  );
};
