import { ExpensesCalculatorData } from "../api";
import { useFormatMessage } from "../../../../intl";
import { Dialog } from "design-system";
import { option } from "fp-ts";
import { ExpensesCalculator } from "./ExpensesCalculator";
import { ExpensesCalculatorProvider } from "./ExpensesCalculatorProvider";
import { MoneyAmount } from "../../../../globalDomain";

type Props = {
  onCancel: () => unknown;
  onSaved: () => unknown;
  calculatorData: ExpensesCalculatorData;
  minMonthlyExpenses: MoneyAmount;
};

export function ExpensesCalculatorDialog(props: Props) {
  const formatMessage = useFormatMessage();

  return (
    <Dialog
      title={formatMessage(
        "StandardLoan.ExpensesConfirmation.Calculator.Form.title"
      )}
      size="small"
      actions={[]}
      onDismiss={option.some(props.onCancel)}
      variant="left"
    >
      <ExpensesCalculatorProvider initialExpenses={props.calculatorData}>
        <ExpensesCalculator
          onSaved={props.onSaved}
          onCancel={props.onCancel}
          data={props.calculatorData}
          minMonthlyExpenses={props.minMonthlyExpenses}
        />
      </ExpensesCalculatorProvider>
    </Dialog>
  );
}
