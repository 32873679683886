import {
  Body,
  Box,
  Button,
  LoadingButton,
  PlusIcon,
  Space,
  Stack,
} from "design-system";
import {
  array,
  monoid,
  nonEmptyArray,
  option,
  record,
  taskEither,
} from "fp-ts";
import {
  constFalse,
  constNull,
  constTrue,
  constVoid,
  flow,
  identity,
  pipe,
} from "fp-ts/function";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Option } from "fp-ts/Option";
import { NonEmptyString } from "io-ts-types/lib/NonEmptyString";
import { useEffect, useMemo, useState } from "react";
import { useFormatMessage } from "../../../intl";
import * as remoteData from "../../../RemoteData";
import { useCommand, useQuery } from "../../../useAPI";
import * as api from "../../api";
import {
  IncomeSourceType,
  SpecialIncomeSourceType,
} from "../../IncomeForm/domain";
import * as reworkApi from "../../Rework/api";
import { ReworkBanner } from "../../Rework/ReworkBanner";
import { useGetAdditionalIncomeStepChanges } from "../../Rework/utils";
import { AdditionalIncomeItem } from "./AdditionalIncomeItem";
import { useAppContext } from "../../../useAppContext";
import { useRemoteData } from "../../../useRemoteData";
import { StepCommentsOutput } from "../../Rework/api";

type Props = {
  onNext: () => unknown;
  onRejected: () => unknown;
  onCounterOffer: () => unknown;
  onPending: () => unknown;
  onEditingStateChange: (state: boolean) => void;
  reworkData: Option<reworkApi.ReworkAdditionalIncomeMap>;
  reworkAll: boolean;
};

type SpecialTypeIncomeSourceCount = { [K in SpecialIncomeSourceType]: number };
type IncomeSourceCount = { [K in IncomeSourceType]: number };

export function AdditionalIncome(props: Props) {
  const [editingPanelIndex, setEditingPanelIndex] = useState<Option<number>>(
    option.none
  );
  const [isAddingNewIncome, setIsAddingNewIncome] = useState(false);

  const formatMessage = useFormatMessage();
  const [hasAdditionalIncome, setHasAdditionalIncome] = useState<
    Option<boolean>
  >(option.of(true));
  const [additionalIncomeList, refreshList] = useQuery(
    api.additionalIncomeList
  );
  const proceedNext = useCommand(api.additionalIncomeNext);

  const [additionalIncomeData, incomeList, listHasNoItems] = useMemo(() => {
    const constantEmptyResponse = () =>
      ({
        additionalIncomeList: [],
        incomeOptions: option.none,
        mainSourceOfIncome: "Employed",
        mainSpecialTypeOfIncome: option.none,
      } as api.AdditionalIncomeListOutput);

    const data = pipe(
      additionalIncomeList,
      remoteData.fold(constantEmptyResponse, constantEmptyResponse, identity)
    );

    const listHasNoItems = data.additionalIncomeList.length === 0;
    const listHasOneItem = data.additionalIncomeList.length === 1;

    return [data, data.additionalIncomeList, listHasNoItems, listHasOneItem];
  }, [additionalIncomeList]);

  const showList = pipe(
    hasAdditionalIncome,
    option.getOrElse(() => false)
  );

  const isEditing = option.isSome(editingPanelIndex) || isAddingNewIncome;

  const onSave = () => {
    setEditingPanelIndex(option.none);
    setIsAddingNewIncome(false);
    refreshList();
  };

  useEffect(() => {
    props.onEditingStateChange(isEditing);
  }, [isEditing]);

  useEffect(() => {
    if (listHasNoItems) {
      setIsAddingNewIncome(true);
    }
  }, []);

  useEffect(() => {
    if (listHasNoItems) {
      setIsAddingNewIncome(true);
    }
  }, [hasAdditionalIncome]);

  const getAdditionalIncomeStepChanges = useGetAdditionalIncomeStepChanges(
    additionalIncomeData.additionalIncomeList,
    pipe(
      props.reworkData,
      option.map(oldData =>
        pipe(
          oldData,
          record.toArray,
          array.map<
            [string, reworkApi.ReworkIncomeOutput],
            reworkApi.ReworkIncomeOutput & { uniqueId: Option<NonEmptyString> }
          >(([k, v]) => ({
            ...v,
            uniqueId: pipe(k, NonEmptyString.decode, option.fromEither),
          }))
        )
      )
    )
  );

  const stepComments = useCommand(reworkApi.stepComments);

  const {
    config: { r6Enabled },
  } = useAppContext();

  const comments = useRemoteData(
    useMemo(
      () => stepComments({ reworkCategories: ["BONITA", "PERSONAL_DATA"] }),
      [r6Enabled]
    )
  );

  const reworkComments = pipe(
    comments,
    remoteData.fold(
      () => option.none,
      () => option.none,
      comments => option.some(comments)
    )
  );

  const reworkBanner = (comments: Option<StepCommentsOutput>) => {
    return pipe(
      props.reworkData,
      option.fold(constNull, () => (
        <ReworkBanner
          changes={getAdditionalIncomeStepChanges}
          stepComments={comments}
        />
      ))
    );
  };

  const areReworkChanges =
    option.isSome(getAdditionalIncomeStepChanges) &&
    getAdditionalIncomeStepChanges.value.length > 0;

  const areReworkComments =
    option.isSome(reworkComments) &&
    option.isSome(reworkComments.value.stepComments) &&
    reworkComments.value.stepComments.value.length > 0;

  const getOtherIncomes = (currentRecordId: Option<string>) => {
    return incomeList.filter(item =>
      pipe(
        currentRecordId,
        option.fold(constTrue, id =>
          pipe(
            item.uniqueId,
            option.exists(uniqueId => uniqueId !== id)
          )
        )
      )
    );
  };
  const filterIncomeSources = (
    currentRecordId: Option<string>
  ): Option<NonEmptyArray<IncomeSourceType>> => {
    const sourceCount: IncomeSourceCount = {
      CompanyOwner: 0,
      Employed: 0,
      EmployedAbroad: 0,
      Freelancer: 0,
      SpecialType: 0,
      TradesmanCoOperatingPerson: 0,
    };

    function isEmploymentBasedIncome(source: IncomeSourceType): boolean {
      return source !== "SpecialType";
    }

    function isEntrepreneurBasedIncome(source: IncomeSourceType): boolean {
      return (
        source === "TradesmanCoOperatingPerson" ||
        source === "CompanyOwner" ||
        source === "Freelancer"
      );
    }

    const usedIncomes = getOtherIncomes(currentRecordId).map(income =>
      pipe(
        income.incomeInfo,
        option.map(incomeInfo => incomeInfo.incomeSource)
      )
    );

    usedIncomes.forEach(value =>
      pipe(
        value,
        option.fold(constNull, currentValue => {
          sourceCount[currentValue] = sourceCount[currentValue] + 1;
        })
      )
    );

    const filtered = pipe(
      additionalIncomeData.incomeOptions,
      option.map(({ sourceOfIncomeOptions }) =>
        pipe(
          sourceOfIncomeOptions,
          array.filter(source => {
            const maxEmployedBased = pipe(
              sourceCount,
              record.filterWithIndex(isEmploymentBasedIncome),
              record.foldMap(monoid.monoidSum)(identity),
              sum => sum < 2
            );

            const maxEntrepreneurBased = pipe(
              sourceCount,
              record.filterWithIndex(isEntrepreneurBasedIncome),
              record.foldMap(monoid.monoidSum)(identity),
              sum => sum < 1
            );

            switch (source) {
              case "EmployedAbroad":
              case "Employed":
                return maxEmployedBased;
              case "SpecialType":
                return sourceCount[source] < 3;
              case "TradesmanCoOperatingPerson":
              case "Freelancer":
              case "CompanyOwner":
                return maxEntrepreneurBased && maxEmployedBased;
            }
          })
        )
      ),
      option.chain(nonEmptyArray.fromArray)
    );
    return filtered;
  };

  const filterSpecialIncomeSources = (
    currentRecordId: Option<string>
  ): Option<NonEmptyArray<SpecialIncomeSourceType>> => {
    const specialIncomeSourceCount: SpecialTypeIncomeSourceCount = {
      Alimony: 0,
      MaternityLeave: 0,
      Pensioner: 0,
    };
    const usedIncomes: SpecialIncomeSourceType[] = [];

    getOtherIncomes(currentRecordId).forEach(income =>
      pipe(
        income.incomeInfo,
        option.fold(
          constVoid,
          incomeInfo =>
            incomeInfo.incomeSource === "SpecialType" &&
            usedIncomes.push(incomeInfo.specialTypeOfIncome)
        )
      )
    );

    usedIncomes.forEach(
      value =>
        (specialIncomeSourceCount[value] = specialIncomeSourceCount[value] + 1)
    );

    const availableIncomes = pipe(
      additionalIncomeData.incomeOptions,
      option.fold(
        () => [],
        options =>
          pipe(
            options.specialTypeOfIncomeOptions,
            option.getOrElse(() => [] as SpecialIncomeSourceType[])
          ).filter(source => specialIncomeSourceCount[source] < 3)
      )
    );

    return pipe(
      availableIncomes,
      option.fromPredicate(incomes => incomes.length > 0),
      option.map(incomes => incomes as NonEmptyArray<SpecialIncomeSourceType>)
    );
  };

  const getSelectedCompanies = (currentRecordId: Option<string>) =>
    getOtherIncomes(currentRecordId)
      .map(income =>
        pipe(
          income.companyInfo,
          option.map(info => ({
            companyName: pipe(
              info.companyName,
              option.getOrElse(() => "")
            ),
            companyIco: pipe(
              info.companyIco,
              option.getOrElse(() => "")
            ),
          })),
          option.getOrElse(() => ({
            companyName: "",
            companyIco: "",
          }))
        )
      )
      .filter(
        company => company.companyName !== "" && company.companyIco !== ""
      );

  const getOldValuesForItem = (
    uniqueId: Option<keyof reworkApi.ReworkAdditionalIncomeMap>
  ): Option<reworkApi.ReworkIncomeOutput> =>
    pipe(
      uniqueId,
      option.chain(uniqueId =>
        pipe(props.reworkData, option.chain(record.lookup(uniqueId)))
      )
    );

  const isReworking = option.isSome(props.reworkData);

  const canAddAdditionalIncome = () => {
    let additional = pipe(
      filterIncomeSources(option.none),
      option.fold(constFalse, list => list.length > 0)
    );
    return additional;
  };

  const renderList = () =>
    pipe(
      additionalIncomeData.incomeOptions,
      option.fold(constNull, incomeOptions => {
        const incomes = incomeList.map((data, index) => (
          <AdditionalIncomeItem
            key={pipe(
              data.uniqueId,
              option.getOrElse(() => `${index}`)
            )}
            index={index}
            additionalIncomeData={option.some(data)}
            incomeOptions={incomeOptions}
            onSave={onSave}
            onEditing={flow(option.some, setEditingPanelIndex)}
            onCancel={() => {
              refreshList();
              setEditingPanelIndex(option.none);
            }}
            isEditing={pipe(
              editingPanelIndex,
              option.exists(editingIndex => editingIndex === index)
            )}
            onDelete={() => {
              refreshList();
              setEditingPanelIndex(option.none);
              setHasAdditionalIncome(option.some(true));
            }}
            disabled={isEditing}
            incomeSources={filterIncomeSources(data.uniqueId)}
            specialIncomeSources={filterSpecialIncomeSources(data.uniqueId)}
            selectedCompanies={getSelectedCompanies(data.uniqueId)}
            isReworking={isReworking}
            oldValues={getOldValuesForItem(data.uniqueId)}
            reworkAll={props.reworkAll}
          />
        ));

        if (isAddingNewIncome || listHasNoItems)
          incomes.push(
            <AdditionalIncomeItem
              key="newIncome"
              index={incomes.length}
              additionalIncomeData={option.none}
              incomeOptions={incomeOptions}
              onSave={onSave}
              onEditing={constVoid}
              onCancel={() => {
                setEditingPanelIndex(option.none);
                setIsAddingNewIncome(false);

                setHasAdditionalIncome(option.some(true));
              }}
              onDelete={constVoid}
              isEditing={true}
              disabled={false}
              incomeSources={filterIncomeSources(option.none)}
              specialIncomeSources={filterSpecialIncomeSources(option.none)}
              selectedCompanies={getSelectedCompanies(option.none)}
              isReworking={isReworking}
              oldValues={option.none}
              reworkAll={props.reworkAll}
              hideCancel={listHasNoItems}
            />
          );
        return incomes;
      })
    );

  return (
    <Stack units={4} column grow shrink>
      {(areReworkChanges || areReworkComments) && reworkBanner(reworkComments)}
      <Body size="medium" weight="regular">
        {formatMessage("StandardLoan.AdditionalIncome.Panel.description")}
      </Body>
      {showList && renderList()}
      {showList && (
        <Box hAlignContent="left">
          <Button
            variant="text"
            size="default"
            label={formatMessage("StandardLoan.AdditionalIncome.Income.add")}
            action={() => setIsAddingNewIncome(true)}
            icon={PlusIcon}
            disabled={isEditing || !canAddAdditionalIncome()}
          />
        </Box>
      )}
      <Box>
        <Space fluid />
        <LoadingButton
          type="submit"
          variant="primary"
          labels={{
            normal: formatMessage("Next"),
            success: formatMessage("Save"),
            loading: formatMessage("Loading"),
            error: formatMessage("Error"),
          }}
          size="default"
          action={pipe(
            hasAdditionalIncome,
            option.fold(
              () => taskEither.fromIO(() => constVoid),
              () =>
                pipe(
                  proceedNext(),
                  taskEither.chain(response =>
                    taskEither.fromIO(() => {
                      switch (response.lfDecision) {
                        case "Approved":
                          return props.onNext();
                        case "Counteroffer":
                          return props.onCounterOffer();
                        case "Rejected":
                          return props.onRejected();
                        case "Pending":
                          return props.onPending();
                      }
                    })
                  )
                )
            )
          )}
          disabled={isEditing}
        />
      </Box>
    </Stack>
  );
}
