import { Loader } from "design-system";
import { constVoid, pipe } from "fp-ts/function";
import { useQuery } from "../../../useAPI";
import * as remoteData from "../../../RemoteData";
import * as api from "../../api";
type Props = {
  onFinish: (hasIncomes: boolean) => void;
  onError: () => void;
};

export function AdditionalIncomesCheck(props: Props) {
  const [getAdditionalIncomes] = useQuery(api.additionalIncomeList);
  pipe(
    getAdditionalIncomes,
    remoteData.fold(
      constVoid,
      () => props.onError,
      response => props.onFinish(response.additionalIncomeList.length > 0)
    )
  );

  return <Loader />;
}
