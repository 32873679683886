import { nonEmptyArray } from "io-ts-types/nonEmptyArray";
import * as t from "io-ts";
import { eq } from "fp-ts";
import { constFalse } from "fp-ts/function";
import { GenericError, MoneyAmount } from "../../globalDomain";
import { RuntimeLocaleKey } from "../../intl";
import { apiCall } from "../../APICall";
import { optionFromNullable } from "io-ts-types/optionFromNullable";
import { Eq } from "fp-ts/Eq";
import { DocumentContentOutput } from "../../Common/documentAPI";

export const CPIAdditionalQuestions = t.type(
  {
    consideringFinancial: t.boolean,
    declared: t.boolean,
    experienceWithCPI: t.boolean,
    knowledge: t.boolean,
  },
  "CPIAdditionalQuestions"
);
export type CPIAdditionalQuestions = t.TypeOf<typeof CPIAdditionalQuestions>;

export const UKontoPackageType = t.keyof({
  BASIC: true,
  MIDDLE: true,
  UPPER: true,
});

export type UKontoPackageType = t.TypeOf<typeof UKontoPackageType>;

export const CreditProtectionInsuranceType = t.keyof({
  Basic: true,
  Standard: true,
  Full: true,
  None: true,
});
export type CreditProtectionInsuranceType = t.TypeOf<
  typeof CreditProtectionInsuranceType
>;

export const CreditProtectionInsurance = t.type({
  type: CreditProtectionInsuranceType,
  name: RuntimeLocaleKey,
  features: t.array(RuntimeLocaleKey),
  price: MoneyAmount,
  eligible: t.boolean,
});

export type CreditProtectionInsurance = t.TypeOf<
  typeof CreditProtectionInsurance
>;

export const CPIPackageList = t.type(
  {
    options: nonEmptyArray(CreditProtectionInsurance),
    selectedInsurance: CreditProtectionInsuranceType,
  },
  "CPIPackageList"
);
export type CPIPackageList = t.TypeOf<typeof CPIPackageList>;

export const SelectedInsurance = t.type(
  {
    selectedInsurance: CreditProtectionInsuranceType,
  },
  "SelectedInsurance"
);
export type SelectedInsurance = t.TypeOf<typeof SelectedInsurance>;

export const InsuranceSolvencyAnswers = t.type(
  {
    firstQuestion: t.boolean,
    secondQuestion: t.boolean,
  },
  "InsuranceSolvencyAnswers"
);
export type InsuranceSolvencyAnswers = t.TypeOf<
  typeof InsuranceSolvencyAnswers
>;

export const eqInsuranceSolvencyAnswers: Eq<InsuranceSolvencyAnswers> = eq.getStructEq(
  {
    firstQuestion: eq.eqBoolean,
    secondQuestion: eq.eqBoolean,
  }
);

const GetInsuranceListOutput = t.type(
  {
    cpiPackageList: CPIPackageList,
  },
  "GetInsuranceListOutput"
);
export type GetInsuranceListOutput = t.TypeOf<typeof GetInsuranceListOutput>;

const GetInsuranceSolvencyAnswersOutput = optionFromNullable(
  t.type(
    {
      cpiAnswers: optionFromNullable(InsuranceSolvencyAnswers),
    },
    "GetInsuranceSolvencyAnswersOutput"
  )
);
export type GetInsuranceSolvencyAnswersOutput = t.TypeOf<
  typeof GetInsuranceSolvencyAnswersOutput
>;

export const insuranceDocument = apiCall({
  inputEq: eq.fromEquals(constFalse),
  path: [
    "packages",
    "loans",
    "standard-loan",
    "insurance",
    "coverage",
    "document",
  ],
  inputCodec: t.void,
  outputCodec: DocumentContentOutput,
});

const AgeLimitError = t.type({
  id: t.literal("AgeLimitError"),
});

export const InsuranceError = t.union([AgeLimitError, GenericError]);

export type InsuranceError = t.TypeOf<typeof InsuranceError>;

export const getInsuranceList = apiCall({
  path: ["packages", "loans", "standard-loan", "insurance", "getList"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: GetInsuranceListOutput,
  errorCodec: InsuranceError,
});

export const getSelectedInsurance = apiCall({
  path: ["packages", "loans", "standard-loan", "insurance", "getSelected"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: SelectedInsurance,
  errorCodec: InsuranceError,
});

export const setAdditionalQuestions = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "customerOffer",
    "cpi",
    "additionalQuestions",
  ],
  inputCodec: CPIAdditionalQuestions,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export const getInsuranceSolvencyAnswers = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "insurance",
    "solvency",
    "answers",
    "get",
  ],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: GetInsuranceSolvencyAnswersOutput,
  errorCodec: InsuranceError,
});

export const setInsuranceSolvencyAnswers = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "insurance",
    "solvency",
    "answers",
    "set",
  ],
  inputCodec: InsuranceSolvencyAnswers,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: GetInsuranceListOutput,
  errorCodec: InsuranceError,
});
