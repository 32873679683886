import { createContext, useContext, useEffect, useState } from "react";
import * as api from "../api";
import { TLAndCLLimits } from "../api";
import { LimitData } from "../../ClientProfile/domain";
import { option, taskEither } from "fp-ts";
import { constant, constVoid, identity, pipe } from "fp-ts/function";
import { useCommand } from "../../useAPI";
import { Reader } from "fp-ts/Reader";
import { IO } from "fp-ts/IO";
import { NonNegative, PositiveInteger } from "design-system";
import { Option } from "fp-ts/Option";
import { CPIPackageList } from "./api";

type LoadingAPIStatus<T> = {
  status: "loading";
  oldData: Option<T>;
};

type ErrorAPIStatus = {
  status: "error";
};

type SuccessAPIStatus<T> = {
  status: "success";
  data: T;
};

type APIStatus<A> = LoadingAPIStatus<A> | ErrorAPIStatus | SuccessAPIStatus<A>;

export type OfferResponse = {
  genericLoan: api.GenericLoanResponseOutput;
  cpiPackageList: Option<CPIPackageList>;
  preaApprovedLimits: TLAndCLLimits;
};

export type LoanOfferState = APIStatus<OfferResponse>;
export type PreapprovedLimitState = APIStatus<LimitData>;
export type RefinancingLoanState = APIStatus<api.RefinancingLoanListOutput>;

export function foldAPIStatus<I, O>(matches: {
  whenSuccess: Reader<I, O>;
  whenLoading: Reader<Option<I>, O>;
  whenError: IO<O>;
}): Reader<APIStatus<I>, O> {
  return state => {
    switch (state.status) {
      case "success":
        return matches.whenSuccess(state.data);
      case "loading":
        return matches.whenLoading(state.oldData);
      case "error":
        return matches.whenError();
    }
  };
}

type HandleUpdateCustomerOffer = (
  values: ConfiguratorValues,
  afterUpdate?: Reader<api.UpdateCustomerOffer, unknown>
) => void;

export interface UpdateCostumerOfferContext {
  loanOfferState: LoanOfferState;
  initialLoanOfferState: LoanOfferState;
  preapprovedLimitState: PreapprovedLimitState;
  refinancingLoanState: RefinancingLoanState;
  updatedOfferStatus: api.UpdateOfferStatus;
  handleUpdateCustomerOffer: HandleUpdateCustomerOffer;
}

export const UpdateCostumerOfferContext = createContext<UpdateCostumerOfferContext>(
  {
    loanOfferState: { status: "loading", oldData: option.none },
    initialLoanOfferState: { status: "loading", oldData: option.none },
    preapprovedLimitState: { status: "loading", oldData: option.none },
    refinancingLoanState: { status: "loading", oldData: option.none },
    updatedOfferStatus: "OK",
    handleUpdateCustomerOffer: constVoid,
  }
);

type UpdateCostumerOfferProps = {
  hasRefinancingCredits: boolean;
  children: JSX.Element;
  onExposureExceeded: () => void;
};

export interface ConfiguratorValues {
  amount: NonNegative;
  tenor: PositiveInteger;
  installmentDay: PositiveInteger;
  salaryTransfer: boolean;
  bankingFeeIncluded: Option<boolean>;
}

export const UpdateCostumerOfferProvider = (
  props: UpdateCostumerOfferProps
) => {
  /* states */
  const [
    initialLoanOfferState,
    setInitialLoanOfferState,
  ] = useState<LoanOfferState>({
    status: "loading",
    oldData: option.none,
  });

  const [
    updatedOfferStatus,
    setUpdatedOfferStatus,
  ] = useState<api.UpdateOfferStatus>("OK");

  const [loanOfferState, setLoanOfferState] = useState<LoanOfferState>({
    status: "loading",
    oldData: option.none,
  });

  const [
    preapprovedLimitState,
    setPreapprovedLimitState,
  ] = useState<PreapprovedLimitState>({
    status: "loading",
    oldData: option.none,
  });

  const [
    refinancingLoanState,
    setRefinancingLoanState,
  ] = useState<RefinancingLoanState>({
    status: "loading",
    oldData: option.none,
  });

  /* api */
  const initialCustomerOffer = useCommand(api.initCustomerOffer);
  const updateCustomerOffer = useCommand(api.updateCustomerOffer);
  const refinancingLoans = useCommand(api.refinancingLoanList);

  const handleUpdateCustomerOffer = (
    values: ConfiguratorValues,
    afterUpdate: Reader<api.UpdateCustomerOffer, unknown> = constVoid
  ) => {
    setLoanOfferState({
      ...loanOfferState,
      status: "loading",
      oldData: pipe(
        loanOfferState,
        foldAPIStatus({
          whenError: constant(option.none),
          whenLoading: identity,
          whenSuccess: option.some,
        })
      ),
    });
    pipe(
      updateCustomerOffer({
        amount: values.amount,
        tenor: values.tenor,
        salaryTransfer: values.salaryTransfer,
        installmentDay: values.installmentDay,
        bankingFeeIncluded: values.bankingFeeIncluded,
      }),
      taskEither.fold(
        () =>
          taskEither.fromIO(() => {
            setLoanOfferState({ ...loanOfferState, status: "error" });
          }),
        data =>
          taskEither.fromIO(() => {
            pipe(
              data.genericLoan,
              option.fold(props.onExposureExceeded, offer => {
                if (
                  data.updateOfferStatus === "EXPOSURE_EXCEEDED" ||
                  data.updateOfferStatus === "GENERIC_PCE_ERROR"
                ) {
                  props.onExposureExceeded();
                }
                setUpdatedOfferStatus(data.updateOfferStatus);
                setLoanOfferState({
                  data: {
                    genericLoan: offer,
                    cpiPackageList: data.cpiPackageList,
                    preaApprovedLimits: data.preapprovedLimits,
                  },
                  status: "success",
                });
              })
            );
            afterUpdate(data);
          })
      )
    )();
  };

  /* first load */
  useEffect(() => {
    pipe(
      initialCustomerOffer(),
      taskEither.fold(
        () =>
          taskEither.fromIO(() => {
            setInitialLoanOfferState({
              ...initialLoanOfferState,
              status: "error",
            });
            setPreapprovedLimitState({
              ...preapprovedLimitState,
              status: "error",
            });
          }),
        initialCustomerOfferData =>
          taskEither.fromIO(() => {
            pipe(
              initialCustomerOfferData.genericLoan,
              option.fold(props.onExposureExceeded, offer => {
                if (
                  initialCustomerOfferData.updateOfferStatus ===
                    "EXPOSURE_EXCEEDED" ||
                  initialCustomerOfferData.updateOfferStatus ===
                    "GENERIC_PCE_ERROR"
                ) {
                  props.onExposureExceeded();
                }
                setUpdatedOfferStatus(
                  initialCustomerOfferData.updateOfferStatus
                );
                setInitialLoanOfferState({
                  data: {
                    genericLoan: offer,
                    cpiPackageList: initialCustomerOfferData.cpiPackageList,
                    preaApprovedLimits:
                      initialCustomerOfferData.preapprovedLimits,
                  },
                  status: "success",
                });
                setPreapprovedLimitState({
                  data: {
                    preapprovedLimits:
                      initialCustomerOfferData.preapprovedLimits,
                  },
                  status: "success",
                });

                pipe(
                  refinancingLoans(),
                  taskEither.fold(
                    () =>
                      taskEither.fromIO(() => {
                        setRefinancingLoanState({
                          ...refinancingLoanState,
                          status: "error",
                        });
                      }),
                    data =>
                      taskEither.fromIO(() => {
                        setRefinancingLoanState({
                          data: data,
                          status: "success",
                        });
                      })
                  )
                )();
              })
            );
          })
      )
    )();
  }, []);

  return (
    <UpdateCostumerOfferContext.Provider
      value={{
        loanOfferState,
        updatedOfferStatus,
        initialLoanOfferState,
        preapprovedLimitState,
        refinancingLoanState,
        handleUpdateCustomerOffer,
      }}
    >
      {props.children}
    </UpdateCostumerOfferContext.Provider>
  );
};

export function useUpdateCustomerOfferContext() {
  const context = useContext(UpdateCostumerOfferContext);

  if (!context) {
    throw new Error("UpdateCostumerOfferContext not provided");
  }

  return context;
}
