import {
  CalculatedField,
  Heading,
  Stack,
  Positive,
  useIsMobileLayout,
  Box,
  Body,
  Space,
  LocalizedString,
} from "design-system";
import { option, either } from "fp-ts";
import { pipe, constVoid } from "fp-ts/function";
import { sequenceS } from "fp-ts/Apply";
import { useFormatMessage, useFormatMoneyAmountValue } from "../../intl";
import * as api from "../api";
import { unsafeNonNegativeInteger } from "../../globalDomain";
import { palette, spaceUnit } from "design-system/lib/styleConstants";

type Props = {
  currentLoanOffer: api.GenericLoanResponseOutput;
  genericLoanOffer: api.GenericLoanOffer;
};
const Field = (props: { label: LocalizedString; value: LocalizedString }) => {
  const isMobileLayout = useIsMobileLayout();
  const fluidOrUnits = {
    fluid: !isMobileLayout ? true : undefined,
    units: isMobileLayout ? 2 : undefined,
  } as { units: number } | { fluid: true };

  return (
    <Box style={{ padding: 4 * spaceUnit }} grow>
      <Stack
        {...fluidOrUnits}
        column={isMobileLayout}
        grow
        vAlignContent="center"
      >
        <Body size="x-small" weight="regular" color={palette.neutral700}>
          {props.label}
        </Body>
        {!isMobileLayout && <Space units={6} />}
        <Body size="medium" weight="medium">
          {props.value}
        </Body>
      </Stack>
    </Box>
  );
};

export function YourOffer({ currentLoanOffer, genericLoanOffer }: Props) {
  const formatMessage = useFormatMessage();

  const formatMoneyAmountValue = useFormatMoneyAmountValue(
    unsafeNonNegativeInteger(0)
  );

  return (
    <Stack column grow units={2}>
      <Heading size="medium" weight="medium">
        {formatMessage("StandardLoan.CustomerOffer.yourOffer")}
      </Heading>

      <>
        {pipe(
          {
            refinancingAmount: Positive.decode(
              pipe(
                genericLoanOffer.refinancingAmount,
                option.getOrElse(() => 0)
              )
            ),
            amount: Positive.decode(currentLoanOffer.amount),
          },
          sequenceS(either.either),
          either.fold(
            constVoid,
            ({ refinancingAmount, amount }) =>
              refinancingAmount > 0 &&
              amount > 0 && (
                <Stack column grow units={2}>
                  <Field
                    label={formatMessage(
                      "StandardLoan.OfferApproved.OfferReview.refinancingAmount"
                    )}
                    value={formatMoneyAmountValue({
                      amount: refinancingAmount,
                      currency: currentLoanOffer.currency,
                    })}
                  />
                  <Field
                    label={formatMessage(
                      "StandardLoan.OfferApproved.OfferReview.newMoney"
                    )}
                    value={formatMoneyAmountValue({
                      amount: currentLoanOffer.amount,
                      currency: currentLoanOffer.currency,
                    })}
                  />
                </Stack>
              )
          )
        )}
      </>

      <CalculatedField
        label={formatMessage(
          "StandardLoan.OfferApproved.OfferReview.totalLoanAmount"
        )}
        value={formatMoneyAmountValue({
          amount: genericLoanOffer.borrowedAmount,
          currency: currentLoanOffer.currency,
        })}
        type="regular"
        error={option.none}
      />
    </Stack>
  );
}
