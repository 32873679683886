import { Option } from "fp-ts/Option";
import { Box, Card, FormSection, Loader } from "design-system";
import { useFormatMessage } from "../../../intl";
import { SelectedCompany } from "../../domain";
import { AdditionalIncomeForm } from "./AdditionalIncomeForm";
import { boolean, option, taskEither } from "fp-ts";
import { constant, constNull, constUndefined, pipe } from "fp-ts/function";
import * as api from "../../api";
import { getBonitaStatus } from "../../api";
import { useCommand, usePollingEffect } from "../../../useAPI";
import {
  IncomeOptionsOutput,
  IncomeOutput,
  IncomeSourceType,
  SpecialIncomeSourceType,
} from "../../IncomeForm/domain";
import { NonEmptyArray } from "fp-ts/NonEmptyArray";
import { Key, useEffect, useState } from "react";
import { ReworkIncomeOutput } from "../../Rework/api";

type Props = {
  index: number;
  key?: Key;
  additionalIncomeData: Option<IncomeOutput>;
  incomeOptions: IncomeOptionsOutput;
  isEditing: boolean;
  disabled: boolean;
  incomeSources: Option<NonEmptyArray<IncomeSourceType>>;
  specialIncomeSources: Option<NonEmptyArray<SpecialIncomeSourceType>>;
  selectedCompanies: SelectedCompany[];
  isReworking: boolean;
  oldValues: Option<ReworkIncomeOutput>;
  onSave: (index: number) => unknown;
  onEditing: (index: number) => unknown;
  onCancel: () => unknown;
  onDelete: () => unknown;
  reworkAll: boolean;
  hideCancel?: boolean;
};

export function AdditionalIncomeItem(props: Props) {
  const formatMessage = useFormatMessage();
  const removeIncome = useCommand(api.additionalIncomeRemove);

  const [loading, setLoading] = useState(false);
  const [errorNo, setErrorNo] = useState(0);
  const [canPoll, setCanPoll] = useState(false);

  const restartPoll = usePollingEffect(getBonitaStatus, {
    intervalMS: 5000,
    disabled: !canPoll,
    shouldPollingContinue: val => val.bonitaStatus === "PENDING",
    onError: () => setErrorNo(curr => curr + 1),
    onSuccess: response => {
      setErrorNo(0);
      if (response.bonitaStatus === "DONE") {
        setCanPoll(false);
        setLoading(false);
        props.onDelete();
      }
    },
  });

  useEffect(() => {
    if (errorNo >= 2) {
      // props.onError();
    }
  }, [errorNo]);

  useEffect(() => {
    if (canPoll) {
      restartPoll();
    }
  }, [canPoll]);

  const initializedIncome = pipe(
    props.additionalIncomeData,
    option.getOrElse<IncomeOutput>(
      constant({
        allowanceInfo: option.none,
        companyInfo: option.none,
        contractInfo: option.none,
        incomeInfo: option.none,
        uniqueId: option.none,
        hasPreapprovedLimits: false,
      })
    )
  );

  if (loading) {
    return (
      <Box hAlignContent="center">
        <Loader />
      </Box>
    );
  }

  return (
    <Card>
      <Box grow shrink column>
        <FormSection
          heading={{
            subtitle: formatMessage(
              "StandardLoan.AdditionalIncome.Income.title",
              { index: props.index + 1 }
            ),
          }}
          edit={pipe(
            props.isEditing || props.disabled,
            boolean.fold(
              () => ({
                label: formatMessage("Identification.clientData.edit"),
                action: () => props.onEditing(props.index),
              }),
              constUndefined
            )
          )}
          remove={pipe(
            props.disabled,
            boolean.fold(
              () =>
                pipe(
                  props.additionalIncomeData,
                  option.fold(constUndefined, ({ uniqueId }) =>
                    pipe(
                      uniqueId,
                      option.fold(constUndefined, uniqueId => ({
                        label: formatMessage("Remove"),
                        action: pipe(
                          { recordId: uniqueId },
                          removeIncome,
                          taskEither.chain(() =>
                            taskEither.fromIO(() => {
                              setCanPoll(true);
                              setLoading(true);
                            })
                          )
                        ),
                      }))
                    )
                  )
                ),
              constUndefined
            )
          )}
        >
          {pipe(
            props.incomeSources,
            option.fold(constNull, incomeSources => (
              <AdditionalIncomeForm
                additionalIncomeData={initializedIncome}
                incomeOptions={props.incomeOptions}
                onSubmit={() => props.onSave(props.index)}
                onCancel={props.onCancel}
                onDelete={props.onDelete}
                incomeSources={incomeSources}
                specialIncomeSources={props.specialIncomeSources}
                selectedCompanies={props.selectedCompanies}
                isEditing={props.isEditing}
                isReworking={props.isReworking}
                oldValues={props.oldValues}
                reworkAll={props.reworkAll}
                hideCancel={props.hideCancel}
              />
            ))
          )}
        </FormSection>
      </Box>
    </Card>
  );
}
