import * as t from "io-ts";
import { MoneyAmount, optionFromUndefined } from "../../../globalDomain";
import { apiCall } from "../../../APICall";
import { constFalse } from "fp-ts/function";
import { array, eq } from "fp-ts";
import { Eq } from "fp-ts/Eq";
import { NonNegativeInteger, PositiveInteger } from "design-system";
import { withFallback } from "io-ts-types/lib/withFallback";

export const CustomExpensesField = t.type({
  key: t.string,
  value: NonNegativeInteger,
  index: NonNegativeInteger,
});

export type CustomExpensesField = t.TypeOf<typeof CustomExpensesField>;

const eqCustomExpensesField: Eq<CustomExpensesField> = eq.getStructEq({
  key: eq.eqString,
  value: eq.eqNumber,
  index: eq.eqNumber,
});

export const NecessaryExpenses = t.type({
  commonPurchasesAmount: withFallback(t.number, 0),
  housingAmount: withFallback(t.number, 0),
  transportAmount: withFallback(t.number, 0),
  clothesAmount: withFallback(t.number, 0),
  repairsAmount: withFallback(t.number, 0),
  healthAmount: withFallback(t.number, 0),
  entertainmentAmount: withFallback(t.number, 0),
  educationAmount: withFallback(t.number, 0),
  alimonyAmount: withFallback(t.number, 0),
  cateringAmount: withFallback(t.number, 0),
  customExpensesFields: t.array(CustomExpensesField),
  otherAmount: withFallback(t.number, 0),
});

export type NecessaryExpenses = t.TypeOf<typeof NecessaryExpenses>;

export const RegularExpenses = t.type({
  savingAmount: withFallback(t.number, 0),
  pensionAmount: withFallback(t.number, 0),
  fusesAmount: withFallback(t.number, 0),
  customExpensesFields: t.array(CustomExpensesField),
  otherAmount: withFallback(t.number, 0),
});

export type RegularExpenses = t.TypeOf<typeof RegularExpenses>;

export const ExpensesCalculatorData = t.type({
  necessaryExpenses: NecessaryExpenses,
  regularExpenses: RegularExpenses,
});

export type ExpensesCalculatorData = t.TypeOf<typeof ExpensesCalculatorData>;

export const ExpensesOutput = t.type(
  {
    minMontlyExpenses: MoneyAmount,
    otherRegularExpenses: optionFromUndefined(MoneyAmount),
    regularMontlyExpenses: MoneyAmount,
    alimonyExpenses: optionFromUndefined(MoneyAmount),
    isCalculatorUsed: t.boolean,
    expensesCalculator: optionFromUndefined(ExpensesCalculatorData),
  },
  "ExpensesResponse"
);

const eqNecessaryExpenses: Eq<NecessaryExpenses> = eq.getStructEq({
  commonPurchasesAmount: eq.eqNumber,
  housingAmount: eq.eqNumber,
  transportAmount: eq.eqNumber,
  clothesAmount: eq.eqNumber,
  repairsAmount: eq.eqNumber,
  healthAmount: eq.eqNumber,
  entertainmentAmount: eq.eqNumber,
  educationAmount: eq.eqNumber,
  alimonyAmount: eq.eqNumber,
  cateringAmount: eq.eqNumber,
  customExpensesFields: array.getEq(eqCustomExpensesField),
  otherAmount: eq.eqNumber,
});

const eqRegularExpenses: Eq<RegularExpenses> = eq.getStructEq({
  savingAmount: eq.eqNumber,
  pensionAmount: eq.eqNumber,
  fusesAmount: eq.eqNumber,
  customExpensesFields: array.getEq(eqCustomExpensesField),
  otherAmount: eq.eqNumber,
});

export const eqExpensesCalculatorData: Eq<ExpensesCalculatorData> = eq.getStructEq(
  {
    necessaryExpenses: eqNecessaryExpenses,
    regularExpenses: eqRegularExpenses,
  }
);

export const SaveExpenseCalculatorDataInput = t.type(
  {
    expensesCalculator: ExpensesCalculatorData,
  },
  "SubmitExpensesBody"
);
export const saveExpenseCalculatorData = apiCall({
  path: [
    "packages",
    "loans",
    "standard-loan",
    "expenses",
    "saveExpensesCalculator",
  ],
  inputCodec: SaveExpenseCalculatorDataInput,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: t.unknown,
});

export type ExpensesOutput = t.TypeOf<typeof ExpensesOutput>;

export const getExpenses = apiCall({
  path: ["packages", "loans", "standard-loan", "expenses"],
  inputCodec: t.void,
  inputEq: eq.fromEquals(constFalse),
  outputCodec: ExpensesOutput,
});
export const BonitaStatus = t.keyof({
  PENDING: true,
  ERROR: true,
  DONE: true,
  NOT_STARTED: true,
});

export type BonitaStatus = t.TypeOf<typeof BonitaStatus>;

export const LfDecision = t.keyof({
  Approved: true,
  Rejected: true,
  Counteroffer: true,
  Pending: true,
});
export type LfDecision = t.TypeOf<typeof LfDecision>;
export const SubmitExpensesResponse = t.type(
  {
    needAdditionalIncomeStep: t.boolean,
    hasAdditionalIncome: t.boolean,
    lfDecision: LfDecision,
  },
  "SubmitExpensesResponse"
);

export type SubmitExpensesResponse = t.TypeOf<typeof SubmitExpensesResponse>;

export const SubmitExpensesInput = t.type(
  {
    otherMontlyExpenses: optionFromUndefined(PositiveInteger),
    disclaimerCheckbox: t.boolean,
    payAlimony: optionFromUndefined(t.boolean),
    alimonyAmount: optionFromUndefined(PositiveInteger),
  },
  "SubmitExpensesBody"
);

export type SubmitExpensesInput = t.TypeOf<typeof SubmitExpensesInput>;

export const SubmitExpensesOutput = t.type(
  {
    needAdditionalIncomeStep: t.boolean,
  },
  "SubmitExpensesResponse"
);

export type SubmitExpensesOutput = t.TypeOf<typeof SubmitExpensesOutput>;

const eqSubmitExpensesInput: Eq<SubmitExpensesInput> = eq.getStructEq({
  otherMontlyExpenses: eq.eqStrict,
  payAlimony: eq.eqStrict,
  alimonyAmount: eq.eqStrict,
  disclaimerCheckbox: eq.eqBoolean,
});

export const submitExpenses = apiCall({
  inputEq: eqSubmitExpensesInput,
  path: ["packages", "loans", "standard-loan", "expenses", "submit"],
  inputCodec: SubmitExpensesInput,
  outputCodec: t.unknown,
});
